import Option from "./Option";

export default interface Tag {
    id: string;
    user_id: string;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
};

export function getOption(tag: Tag): Option { 
    return {
        value: tag.id,
        type: 'App\\Models\\Tag',
        label: tag.name,
    }
}
