import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import ScreenGroupResponse from "./ScreenGroupResponse";
import ScheduleResponse from "../schedule/ScheduleResponse";
import Screen from "../../../models/Screen";
import ApiResponse from "../../ApiResponse";
import ScreenGroup from "../../../models/ScreenGroup";
import ScreenResponse from "../screen/ScreenResponse";
import PaginateResponse from "../../PaginateResponse";
import QueryParams from "../../../models/QueryParams";

export default class ScreenGroupService extends BaseService {

    fetch(page: number = 1, search: string = ""): Promise<AxiosResponse<ScreenGroupResponse>> {
        let uri: string = `/screen-groups?page=${page}`;

        if(search !== "") uri += `&q=${search}`;

        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    schedulesByScreenGroupId(id: string): Promise<AxiosResponse<ApiResponse<ScheduleResponse>>> {
        let uri: string = `/screen-groups-schedules/${id}`;
        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    store(formData: FormData): Promise<AxiosResponse<ApiResponse<ScreenGroup>>> {
        return this.axios.post(`/screen-groups`, formData);
    }

    show(id: string): Promise<AxiosResponse<ApiResponse<ScreenGroup>>> {
        return this.axios.get(`/screen-groups/${id}`, this.getCancelTokenStructure() );
    }

    attachScreen(id: string, screenIds: string[]): Promise<AxiosResponse<ApiResponse<ScreenGroup>>> {
        return this.axios.post(`/screen-groups/${id}/attach-screen`, {screen_ids: screenIds});
    }

    detachScreen(id: string, screenIds: string[]): Promise<AxiosResponse<ApiResponse<ScreenGroup>>> {
        return this.axios.post(`/screen-groups/${id}/detach-screen`, {screen_ids: screenIds});
    }

    update(id: string, screenGroup: ScreenGroup | FormData): Promise<AxiosResponse<ApiResponse<ScreenGroup>>> {
        return this.axios.put(`/screen-groups/${id}`, screenGroup);
    }

    delete(id: string): Promise<AxiosResponse<ApiResponse<ScreenGroup>>> {
        return this.axios.delete(`/screen-groups/${id}`);
    }

    fetchScreensNotInScreenGroup(params: QueryParams): Promise<AxiosResponse<PaginateResponse<Array<Screen>>> > {
        let uri: string = `/screen-groups/${params.id}/screens-not-in-group?page=${params.page}`;

        if(params.search && params.search !== "") uri += `&q=${params.search}`;

        if(params.tags && params.tags.length > 0) uri += `&tag_names=${params.tags.join(',')}`;

        return this.axios.get(uri, this.getCancelTokenStructure());
    }

}
