import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import ApiResponse from "../../ApiResponse";
import TagsResponse from "./TagsResponse";
import Tag from "../../../models/Tag";

export default class TagsService extends BaseService {

    fetch(page: number = 1, search: string = ""): Promise<AxiosResponse<TagsResponse>> {
        let uri: string = `/tags?page=${page}`;

        if(search !== "") uri += `&q=${search}`;

        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    store(formData: FormData): Promise<AxiosResponse<ApiResponse<Tag>>> {
        return this.axios.post(`/tags`, formData);
    }

    show(id: string): Promise<AxiosResponse<ApiResponse<Tag>>> {
        return this.axios.get(`/tags/${id}`, this.getCancelTokenStructure() );
    }

    update(id: string, formData: FormData): Promise<AxiosResponse<ApiResponse<Tag>>> {
        return this.axios.put(`/tags/${id}`, formData);
    }

    delete(id: string): Promise<AxiosResponse<ApiResponse<Tag>>> {
        return this.axios.delete(`/tags/${id}`);
    }

}
