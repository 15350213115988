import React from 'react';
import { useSelector } from "react-redux";
import User from "../../models/User";
import MenuItem from '../MenuItem/MenuItem';

interface AsideMenuProps {};

const AsideMenu: React.FC<AsideMenuProps> = (props: AsideMenuProps) => {

    const authReducer = useSelector( (store: any) => store.authReducer );
    const user: User = authReducer.user ?? {};
    
    const _getProvMenu = () => {

        const actions: string[] = user.actions ?? [];

        let provMenu = [
            {
                link: '/dashboard',
                icon: 'iconsminds-home',
                text: 'Dashboard'        
            },
            {
                 link: '/schedule',
                 icon: 'iconsminds-calendar-4',
                 text: 'Schedule'        
            },
            {
                link: '/screens',
                icon: 'iconsminds-monitor',
                text: 'Screens'        
            },
            {
                link: '/screengroups',
                icon: 'iconsminds-monitor---laptop',
                text: 'Screen Groups'        
            },
            {
                link: '/eink',
                icon: 'iconsminds-tablet-3',
                text: 'Ink'        
            },
            {
                link: '/playlists',
                icon: 'iconsminds-voicemail',
                text: 'Playlists'        
            },
            {
                link: '/library',
                icon: 'iconsminds-books',
                text: 'Library'        
            },
            {
                link: '/tags',
                icon: 'iconsminds-tag',
                text: 'Tags'        
            },
        
        ];

        if(actions.includes('user.store')) {
            provMenu.push(
                {
                    link: '/hierarchy',
                    icon: 'iconsminds-network',
                    text: 'Hierarchy'    
                }
            );
        }

        provMenu.push(
            {
                link: '/admin',
                icon: 'iconsminds-gear',
                text: 'Administration'        
            }
        );

        return provMenu;

    }

    return(
        <aside className='main-menu'>
            <ul>
                {
                    _getProvMenu().map( (item, index) => {
                        return (
                            <MenuItem 
                                key={index}
                                link={item.link}
                                text={item.text}
                                icon={item.icon}
                            />
                        )
                    })
                }
            </ul>
            <div className="main-menu__appVersion">
                { process.env.REACT_APP_VERSION }
            </div>
        </aside>
    );

};

export default React.memo(AsideMenu);
