import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface CactusModalProps {
    show: boolean;
    minWidth?: string;
    width?: string;
    maxWidth?: string;
    overflow?: string;
    shouldCloseOnOverlayClick?: boolean;
    overlayBackgroundColor?: string;
    contentBackgroundColor?: string; // header
    bodyBackgroundColor?: string;
    title?: string | null | undefined;
    removeVerticalSpacing?: boolean;
    children: React.ReactNode;
    onClose: () => void;
    disableOnClose?: boolean;
};

Modal.setAppElement('#root');

const CactusModal: React.FC<CactusModalProps> = (props: CactusModalProps) => {

    const customStyles = {
        overlay: {
            backgroundColor: props.overlayBackgroundColor ?? 'rgba(0, 0, 0, .8)',
            zIndex: 9999,
        },
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            transform             : 'translate(-50%, -50%)',
            minWidth              : props.minWidth ?? '60vw',
            width                 : props.width ?? "auto",
            maxWidth              : props.maxWidth ?? '100vw',
            border                : 'none',
            backgroundColor       : props.contentBackgroundColor ?? '#121212',
            maxHeight             : '100vh',
            overflow              : props.overflow ?? 'auto',
        },
    };

    const [modalIsOpen, setIsOpen] = useState<boolean>(props.show);

    useEffect(() => {
        setIsOpen(props.show);
    }, [props.show]);

    const _renderHeader = () => {

        return(
            <div>
                <div className="d-flex align-items-center px-3 py-2">
                    <span className="font-weight-bold">{props.title ?? ""}</span>
                    <div 
                        className="ml-auto pointer p-2"
                        onClick={() => {
                            if(props.disableOnClose) return;
                            props.onClose();
                        }}
                    >
                        <FontAwesomeIcon 
                            icon={faTimes}
                            fill={props.disableOnClose ? "#333333" : "#969696"}
                            size="1x"
                        />
                    </div>
                </div>
                <hr className="m-0 bg-hr-color" />
            </div>
        );

    }

    let removeVerticalSpacing: boolean = props.removeVerticalSpacing ?? false;
    let bodyBackgroundColorStr: string = props.bodyBackgroundColor ?? "#151515";

    return(
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => props.onClose()}
                style={customStyles}
                shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick ?? false}
            >
                {_renderHeader()}
                <div className={`${removeVerticalSpacing ? "px-3 py-0" : "p-3"}`} style={{ backgroundColor: bodyBackgroundColorStr }}>
                    {props.children}
                </div>
            </Modal>
        </>
    );

};

export default React.memo(CactusModal);
