import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DashboardWrapper,
    DashboardTitle,
    SearchDebounce,
    CactusModal,
    CreateTagForm,
    ModalDeleteConfirm,

} from '../../components';

import ApiResponse from '../../services/ApiResponse';
import { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import { IconLoader } from '../../assets/icons/IconLoader';

import TagsService from '../../services/use_case/tags/TagsService';
import Tag from '../../models/Tag';
import TagsResponse from '../../services/use_case/tags/TagsResponse';

import TagItem from '../../components/TagItem';
import ScreenGroupItemSkeleton from '../../components/ScreenGroupItem/ScreenGroupItemSkeleton';

interface TagsProps {};

export interface TagInputs {
    name: string;
    description: string;
}

const Tags: React.FC<TagsProps> = (props: TagsProps) => {

    const [t] = useTranslation();

    const [search, setSearch] = useState<string>('');
    const [isLoading, setisLoading] = useState(false)
    const [pagination, setPagination] = useState<any>();
    const [_tags, setTags] = useState<Tag[]>([]);
    const [selectedTag, setselectedTag] = useState<Tag | null>(null);

    const [creatingTag, setCreatingTag] = useState(false);
    const [editingTag, setEditingTag] = useState(false)
    const [deletingTag, setDeletingTag] = useState(false)

    const _tagService:TagsService = new TagsService();

    useEffect(() => {
        setisLoading(true)
        _fetchTags(1);
    }, []);

    const _fetchTags = async (page?: number) => {
        try {
            const tagServiceResponse: AxiosResponse<TagsResponse> = await _tagService.fetch(page, search);
            setPagination(tagServiceResponse.data.data);
            const tagServiceData: Tag[] = tagServiceResponse.data.data?.data ?? [];
            console.log ("tags", tagServiceData)
            setTags((prevTags) => prevTags.concat(tagServiceData));
            setisLoading(false)
        }
        catch (error) {
        }
    }

    const refreshTags = () => {
        setisLoading(true);
        setTags([]);
        _fetchTags(1);
    }

    const _handleCreateTag = () => {
        setselectedTag(null)
        setEditingTag(false)
        setCreatingTag(true)
    }

    const _handleEditTag = (tag: Tag) => {
        setCreatingTag(true)
        setselectedTag(tag)
        setEditingTag(true)
    }

    const _handleDeleteTag = (tag: Tag) => {
        setselectedTag(tag)
        setDeletingTag(true)
    }

    const _createEditTag = async (data: FormData) => {
        setCreatingTag(true)
        try {
            if(editingTag) {
                if(!selectedTag) return
                const response:AxiosResponse<ApiResponse<Tag>> = await _tagService.update(selectedTag.id, data)
                const newTags = response.data.data;

                if(!newTags) return;
                setTags(prevTags => prevTags.map((tag: Tag) => {
                    if(tag.id === selectedTag.id) {
                        return newTags;
                    }
                    return tag;
                }));
            } else {
                const response:AxiosResponse<ApiResponse<Tag>> = await _tagService.store(data)
                refreshTags();
                
            }
            setCreatingTag(false)
            setEditingTag(false)
            
        } catch(error) {
            setCreatingTag(false)
            setEditingTag(false)
        }
        
    }

    const _deleteTag = async() => {
    
        if(!selectedTag) return;

        try {
            const response:AxiosResponse<ApiResponse<Tag>> = await new TagsService().delete(selectedTag.id)
            //setScreenGroups(screenGroups.filter(group => group.id !== selectedScreenGroup.id));
            if (response.status === 200) {
                setTags(prevTags => prevTags.filter(tag => tag.id !== selectedTag.id));
                setDeletingTag(false)
            }
            
        } catch (error) {
            setDeletingTag(false)
        }
    }

    const _renderSkeletonLoading = () => {
        return Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mb-3">
                    <ScreenGroupItemSkeleton />
                </div>
            );
        });
    }

    const _renderTags = () => {

        if(isLoading) {
            return _renderSkeletonLoading();
        }

        let tags = _tags?.map((tag: Tag, index: number) => {
            return(
                <div className='mt-3' key={tag.id}>
                    <TagItem
                        tag={tag}
                        openModal={_handleEditTag}
                        openDeleteModal={_handleDeleteTag}
                    />
                </div>
            )
        }) ?? [];

        if(tags.length === 0) {
            return(
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                        {t('No Result')}
                    </div>
                </div>
            )
        }

        return (
            <InfiniteScroll
                pageStart={pagination?.from}
                loadMore={() => {
                    _fetchTags(pagination?.current_page ? pagination?.current_page + 1 : 1)
                }}
                hasMore={pagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                {tags}
            </InfiniteScroll>
        );
    }

    return(
            <DashboardWrapper>
                <div className="row">
                    
                    <div className="col-12 col-md-6">
                        
                        <DashboardTitle
                            title={t('Tags')}
                            isLoading={isLoading}
                            withRefreshIcon={!isLoading}
                            refreshIconCallBack={() => {
                                refreshTags();
                            }}
                        />
    
                        <SearchDebounce
                            placeholder={`${t('Name')}`}
                            borderColor="transparent"
                            onChange={(text: string) => setSearch(text)}
                            debounceFinish={(text: string) => refreshTags()}
                        />
    
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="d-flex mt-1">
                            <button
                                className="btn btn-success ml-auto"
                                disabled={isLoading}
                                onClick={ () => _handleCreateTag() }
                            >
                                { t('New_tag') }
                            </button>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        {_renderTags()}
                    </div>
    
                </div>

                <CactusModal 
                    show={creatingTag}
                    title={ editingTag? t('Edit_tag') : t('Create_tag') }
                    onClose={() => {
                        setCreatingTag(false)
                        setEditingTag(false)
                    }}
                    minWidth="500px"
                >
                    <div className='row'>
                        <CreateTagForm
                            
                            isEditingTag={editingTag}
                            tag={selectedTag}
                            handleOnSubmit={_createEditTag}
                        />
                    </div>
                </CactusModal>

                <ModalDeleteConfirm 
                    show={deletingTag}
                    title={t('Delete_tag')}
                    onCloseFunction={setDeletingTag}
                    onCloseFunctionParameter={false}
                    overrideEntityTranslationAbove={ t('Are_you_sure_to_delete_current_f_') }
                    entityTranslation={t('tag')}
                    buttonLoading={false}
                    onClickFunctionButton={ _deleteTag }
                    buttonText={t('Delete')}
                />

                
            </DashboardWrapper>
        );

};

export default React.memo(Tags);
