import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { 
    CactusLabelInput,
    CactusModal,
    DashboardTitle,
    DashboardWrapper,
    ModalDeleteConfirm,
    ScreenGroupItem,
    SearchDebounce,
} from '../../components';
import * as actionCreator from "../../actions";
import ScreenGroupModel from '../../models/ScreenGroup';
import Playlist, { getOption } from '../../models/Playlist';
import Option from '../../models/Option';
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import ScreenGroupService from '../../services/use_case/screengroup/ScreenGroupService';
import ApiResponse from '../../services/ApiResponse';
import { AxiosResponse } from 'axios';
import PlaylistService from '../../services/use_case/playlist/PlaylistService';
import ScreenGroupItemSkeleton from '../../components/ScreenGroupItem/ScreenGroupItemSkeleton';
import InfiniteScroll from 'react-infinite-scroller';
import { IconLoader } from '../../assets/icons/IconLoader';
import { set } from 'nprogress';

interface ScreenGroupProps {};

export interface ScreenGroupInputs {
    name: string;
}

const ScreenGroup: React.FC<ScreenGroupProps> = (props: ScreenGroupProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [search, setSearch] = useState<string>('');
    const [defaultOption, setDefaultOption] = useState<Option[]>([]);
    const [creatingScreenGroup, setcreatingScreenGroup] = useState(false);
    const [isLoadingCreateScreenGroup, setisLoadingCreateScreenGroup] = useState(false);

    const [editingScreenGroup, setEditingScreenGroup] = useState(false);
    const [isUpdatingForm, setisUpdatingForm] = useState(false);
    const [deletingScreenGroup, setDeletingScreenGroup] = useState(false);
    const [screenGroupId, setScreenGroupId] = useState<string | null>(null);

    const [screenGroups, setScreenGroups] = useState<ScreenGroupModel[]>([]);
    const [selectedScreenGroup, setselectedScreenGroup] = useState<ScreenGroupModel | null>(null);
    

    const screenGroupResponse = useSelector( (store: any) => store.screenGroupReducer );
    
    const isLoading = screenGroupResponse?.isLoading
    const pagination: any = screenGroupResponse?.data;
    const data: any = pagination?.data ?? [];

    useEffect(() => {

        _fetchScreenGroups(1);
        
        return () => {
            _setEmptyScreenGroup();
            //dispatch( actionCreator.screenGroupCancelRequest() );
        }

    }, []);

    const _fetchScreenGroups = (page?: number) => {
        dispatch( actionCreator.screenGroupCancelRequest() );
        dispatch( actionCreator.screenGroupFetch(page, search) );
        setScreenGroups(data);
    }

    const _setEmptyScreenGroup = () => {
        dispatch( actionCreator.screenGroupChangeValue('data', null) );
        setDefaultOption([]);
    }

    const _deleteScreenGroup = async() => {
        if(!selectedScreenGroup?.id) return;
        try {
            const updateResponse:AxiosResponse<ApiResponse<ScreenGroupModel>> = await new ScreenGroupService().delete(selectedScreenGroup.id)
            //setScreenGroups(screenGroups.filter(group => group.id !== selectedScreenGroup.id));
            const newData = data.filter((group: any) => group.id !== selectedScreenGroup.id)
            const newPagination = {...pagination};
            newPagination.data = newData;
            dispatch( actionCreator.screenGroupChangeValue('data', newPagination) );
            setScreenGroupId('');
            setEditingScreenGroup(false)
            setDeletingScreenGroup(false)
        } catch (error) {
            setEditingScreenGroup(false)
            setDeletingScreenGroup(false)
        }
    }

    const _handleCreateScreenGroup = () => {
        setcreatingScreenGroup(true)
    }

    const _handleEditScreenGroup = (screenGroup: ScreenGroupModel) => {
        setselectedScreenGroup(screenGroup)
        setEditingScreenGroup(true)
    }

    //const [storedScreen, setstoredScreen] = useState<ScreenGroupModel | null>(null)

    const { handleSubmit, register, errors } = useForm<ScreenGroupInputs>();
    const textValidator = register(useFormValidatorText());
    
    const _handleOnSubmit = async(formData: FormData) => {

        setisLoadingCreateScreenGroup(true);
        try {
            let storeScreenGroupResponse:AxiosResponse<ApiResponse<ScreenGroupModel>> = await new ScreenGroupService().store(formData);
            //setstoredScreen(storeScreenGroupResponse.data.data)

            const newData = storeScreenGroupResponse.data.data;

            if(!newData) return;
            //setScreenGroups([storeScreenGroupResponse.data.data, ...screenGroups ?? []]);

            const newPagination = {...pagination};
            newPagination.data = newPagination.data.concat(newData);
            dispatch( actionCreator.screenGroupChangeValue('data', newPagination) );

            //dispatch( actionCreator.screenGroupFetch() );
            setisLoadingCreateScreenGroup(false);
            setcreatingScreenGroup(false);
            
        } catch (err){
            setisLoadingCreateScreenGroup(false);
            setcreatingScreenGroup(false);
        }
    }
    
    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className={`btn btn-cactus mt-2 ml-auto ${isLoadingCreateScreenGroup && 'btn-cactus--loading'}`}
                disabled={isLoadingCreateScreenGroup ?? false}
            >
                {t('Create_screengroup')}
            </button>
        );
    }


    const _renderBtnEditSubmit = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
                disabled={isUpdatingForm ?? false}
            >
                {t('Update_screengroup')}
            </button>
        );
    }

    const _renderBtnDelete = () => {
        return (
            <button 
                type="button"
                onClick={ () => setDeletingScreenGroup(true) }
                className="btn btn-cactus--red mt-2"
                disabled={isUpdatingForm ?? false}
            >
                {t('Delete_screengroup')}
            </button>
        )
    }
    
    const _renderCreateScreenGroupForm = () => {
        return (
            <div className="col-12">
                <form onSubmit={handleSubmit(_handleOnSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <CactusLabelInput
                                labelTitle={t('Screengroup_name')}
                                inputAttributes={{
                                    id: "name",
                                    name: "name",
                                    type:  "name",
                                    placeholder: t('Name'),
                                    autoComplete: "name",
                                    ref: textValidator,
                                }}
                                errorMessage={errors.name ? errors.name.message : null}
                            />
                        </div>
                        <div className="col-12 d-flex">
                            { _renderBtnSubmit() }
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const _handleOnSubmitEdit =  async(data:FormData) => {

        setEditingScreenGroup(true)
        try {
            if(!selectedScreenGroup) return;
            const updateResponse:AxiosResponse<ApiResponse<ScreenGroupModel>> = await new ScreenGroupService().update(selectedScreenGroup.id, data)
            console.log('updateResponse', updateResponse)
            setEditingScreenGroup(false)

            const newData = updateResponse.data.data;
            if(!newData) return;

            const newPagination = {...pagination};
            newPagination.data = newPagination.data.map((group: any) => {
                if(group.id === newData.id) {
                    return newData;
                }
                return group;
            });
            dispatch( actionCreator.screenGroupChangeValue('data', newPagination) );
            
            //setscreenGroup(updateResponse.data.data!)
            //dispatch( actionCreator.screenGroupFetch() );
            
        } catch(error) {
            setisUpdatingForm(false)
        }
        
    }

    /*const _handleOnSubmitWithId = async (data: ScreenGroupInputs) => {
        //setIsCreating(true);
        try {
            // Lógica para enviar los datos del formulario al servidor
            //await _screenGroupService.create(data);
            //setIsCreating(false);
            setcreatingScreenGroup(false);
            _fetchScreenGroups(1); // Refrescar la lista después de crear
        } catch (error) {
            //setIsCreating(false);
            console.error('Error creating screen group:', error);
        }
    };*/

    const _renderEditScreenGroupForm = () => {
        return (
            <div className="col-12">
                <form onSubmit={handleSubmit(_handleOnSubmitEdit)}>
                    <div className="row">
                        <div className="col-12">
                        <CactusLabelInput
                                    labelTitle={t('Name')}
                                    inputAttributes={{
                                        id: "name",
                                        name: "name",
                                        type:  "text",
                                        placeholder: t('Name'),
                                        defaultValue: selectedScreenGroup?.name,
                                        autoComplete: "name",
                                        ref: textValidator,
                                    }}
                                    errorMessage={errors.name ? errors.name.message : null}
                                />
                        </div>
                        <div className="col-6 d-flex">
                            { _renderBtnEditSubmit() }
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            { _renderBtnDelete() }
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const _renderSkeletonLoading = () => {
        return Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mb-3">
                    <ScreenGroupItemSkeleton />
                </div>
            );
        });
    }

    const _renderScreenGroups = () => {

        if(isLoading) {
            return _renderSkeletonLoading();
        }

        let screens = data?.map((screenGroup: ScreenGroupModel, index: number) => {
            return(
                <ScreenGroupItem
                    key={index}
                    screenGroup={screenGroup}
                    defaultOption={defaultOption}
                    openModal={_handleEditScreenGroup}
                />
            )
        }) ?? [];

        if(screens.length === 0) {
            return(
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                        {t('No screengroups')}
                    </div>
                </div>
            )
        }

        return(
            <InfiniteScroll
                pageStart={pagination?.from}
                loadMore={() => {
                    _fetchScreenGroups(pagination?.current_page ? pagination?.current_page + 1 : 1)
                }}
                hasMore={pagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                {screens}
            </InfiniteScroll>
        );

    }
    
    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-12 col-md-6">
                    <DashboardTitle 
                        title={t('Screen Groups')}
                        isLoading={isLoading}
                        withRefreshIcon={!isLoading}
                        refreshIconCallBack={() => {
                            _fetchScreenGroups(1);
                        }}
                    />

                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => setSearch(text)}
                        debounceFinish={(text: string) => _fetchScreenGroups(1)}
                    />

                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex mt-1">
                        <button
                            className="btn btn-success ml-auto"
                            disabled={isLoading}
                            onClick={ () => _handleCreateScreenGroup() }
                        >
                            { t('New_screengroup') }
                        </button>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                {_renderScreenGroups()}
            </div>

            <CactusModal 
                show={creatingScreenGroup}
                title={ t('New_screengroup') }
                onClose={() => {
                    setcreatingScreenGroup(false)
                    //setstoredScreen(null)
                }}
                minWidth="300px"
            >
                <div className="row">
                    
                    {
                        _renderCreateScreenGroupForm()
                    }
                    
                </div>
            </CactusModal>

            <CactusModal 
                show={editingScreenGroup}
                title={ t('Update_screengroup') }
                onClose={() => {
                    setEditingScreenGroup(false)
                    //setstoredScreen(null)
                }}
                minWidth="300px"
            >
                <div className="row">
                    
                    {
                        _renderEditScreenGroupForm()
                    }
                    
                </div>
            </CactusModal>

            <ModalDeleteConfirm 
                show={deletingScreenGroup}
                title={t('Delete_screengroup')}
                onCloseFunction={setDeletingScreenGroup}
                onCloseFunctionParameter={false}
                overrideEntityTranslationAbove={ t('Are_you_sure_to_delete_current_m_') }
                entityTranslation={t('screengroup')}
                buttonLoading={isUpdatingForm}
                onClickFunctionButton={_deleteScreenGroup}
                buttonText={t('Delete')}
            />
            
        </DashboardWrapper>
    );

};

export default React.memo(ScreenGroup);
