import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import { CactusLabelInput } from '../../components';
import Tag from '../../models/Tag';

interface CreateTagFormProps {
    isEditingTag?: boolean;
    tag?: Tag | null
    handleOnSubmit: (data: FormData) => void
};

const CreateTagForm: React.FC<CreateTagFormProps> = (props: CreateTagFormProps) => {

    const [t] = useTranslation();
    const { handleSubmit, register, errors } = useForm();
    const textValidator = register(useFormValidatorText());

    const _renderBtn = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
            >
                {props.isEditingTag? t('Edit_tag') : t('Create_tag')}
            </button>
        );
    }

    return(
        <div className="col-12">
            <form onSubmit={handleSubmit(props.handleOnSubmit)}>
                <div className="row">
                    <div className="col-12 mb-2">
                        <CactusLabelInput
                            labelTitle={t('Tag_name')}
                            inputAttributes={{
                                id: "name",
                                name: "name",
                                type:  "text",
                                defaultValue: props.tag?.name,
                                placeholder: t('Name'),
                                autoComplete: "name",
                                ref: textValidator
                            }}
                            errorMessage={errors.name ? errors.name.message : null}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <CactusLabelInput
                            labelTitle={t('Tag_description')}
                            inputAttributes={{
                                id: "description",
                                name: "description",
                                type:  "text",
                                defaultValue: props.tag?.description,
                                placeholder: t('Description'),
                                autoComplete: "description",
                            }}
                            errorMessage={errors.description ? errors.description.message : null}
                        />
                    </div>
                    <div className="col-12 d-flex">
                        {_renderBtn() }
                    </div>
                </div>
            </form>
        </div>
    );

};

export default React.memo(CreateTagForm);
