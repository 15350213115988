import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleEvent, ScheduleInputs } from '../../containers/Schedule/Schedule';
import { useForm } from 'react-hook-form';
import CactusLabelInput from '../Cactus/CactusLabelInput';
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import moment from 'moment';
import 'moment/locale/es';
import Option from "../../models/Option";
import SelectAsyncPaginate from '../SelectAsyncPaginate';

interface CreateEditScheduleProps {
    disabled: boolean;
    selectedScheduleEvent: ScheduleEvent | null;
    playlistValue: Option | null;
    setUpdatingSchedule: (value: boolean) => void;
    setDeletingScheduleForm: (value: boolean) => void;
    onPlaylistChange: (playlistValue: Option | null) => void;
    onSubmit: (formData: Screen[]) => void;
};

const CreateEditSchedule: React.FC<CreateEditScheduleProps> = (props: CreateEditScheduleProps) => {

    const [t] = useTranslation();
    const { handleSubmit, register, errors } = useForm<ScheduleInputs>();
    const textValidator = register(useFormValidatorText());
    const [playlistValue, setPlaylistValue] = useState(props.playlistValue);

    useEffect(() => {
        props.onPlaylistChange(playlistValue);
    }, [playlistValue]);

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className={`btn btn-cactus mt-2 ml-auto `}
                //${isLoadingCreateScreen && 'btn-cactus--loading'}
                //disabled={isLoadingCreateScreen ?? false}
            >
                {t('Create_schedule')}
            </button>
        );
    }

    const _renderBtnDelete = () => {
        return (
            <button 
                type="button"
                onClick={ () => props.setDeletingScheduleForm(true) }
                className={`btn btn-danger mt-2 `}
                //${isLoadingCreateScreen && 'btn-cactus--loading'}
                //disabled={isLoadingCreateScreen ?? false}
            >
                {t('Delete_schedule')}
            </button>
        );
    }

    const _renderBtnUpdate = () => {
        return (
            <button 
                type="submit"
                onClick={() => props.setUpdatingSchedule(true)}
                className={`btn btn-cactus mt-2 ml-auto`}
                //${isLoadingCreateScreen && 'btn-cactus--loading'}
                //disabled={isLoadingCreateScreen ?? false}
            >
                {t('Update_schedule')}
            </button>
        );
    }

    const _returnStartDate = (): string => {
            if(!props.selectedScheduleEvent?.start) return "";
            return moment(props.selectedScheduleEvent?.start).format("YYYY-MM-DDTHH:mm");
        }
    
        const _returnEndDate = (): string => {
            if(!props.selectedScheduleEvent?.end || moment(props.selectedScheduleEvent?.end).format("YYYY-MM-DD") == '9999-12-31') return "";
            return moment(props.selectedScheduleEvent?.end).format("YYYY-MM-DDTHH:mm");
        }

    return(
            <div>
                <form onSubmit={handleSubmit(props.onSubmit)}>
                    <div>
                        <div className='mt-2'>
                            <label>{t('Schedule_name')}</label>
                            <CactusLabelInput
                                labelTitle=""
                                inputAttributes={{
                                    id: "name",
                                    name: "name",
                                    type:  "text",
                                    defaultValue: props.selectedScheduleEvent?.title,
                                    placeholder: t('Name'),
                                    autoComplete: "name",
                                    ref: textValidator,
                                }}
                                errorMessage={errors.name ? errors.name.message : null}
                            />
                        </div>
                        
                        <div className='mt-2 mb-2'>
                            <label className='mb-3'>{t('Playlist')}</label>
                                <SelectAsyncPaginate
                                    value={playlistValue}
                                    onChange={(selectedOption: Option) => {
                                        setPlaylistValue(selectedOption);
                                    }}
                                />
                        </div>

                        { props.selectedScheduleEvent?.id ? (
                                <div className='mt-2'>
                                    <label className='mt-2'>{t('Screen/Screen Group')}</label>
                                    <CactusLabelInput
                                        labelTitle=""
                                        inputAttributes={{
                                            id: "device_name",
                                            name: "device_name",
                                            type:  "text",
                                            defaultValue: props.selectedScheduleEvent?.device_name,
                                            placeholder: t('Name'),
                                            autoComplete: "name",
                                            disabled: true,
                                            ref: textValidator,
                                        }}
                                        errorMessage={errors.name ? errors.name.message : null}
                                    />
                                </div>
                            ) : (null)
                        }

                        <div className={props.selectedScheduleEvent?.id ? 'row mt-2' : 'row mt-3'}>
                            <div className='col-6'>
                                <p className='date-selector'>
                                    {t('Schedule_startDate')} 
                                </p>
                                <input 
                                    className='date-picker' 
                                    type="datetime-local" 
                                    name="start_date" 
                                    id="" 
                                    defaultValue={_returnStartDate()} 
                                    ref={register}
                                />
                            </div>

                            <div className='col-6'>
                                <p className='date-selector'>
                                    {t('Schedule_endDate')} 
                                </p>
                                <input 
                                    className='date-picker' 
                                    type="datetime-local" 
                                    name="end_date" 
                                    id="" 
                                    defaultValue={_returnEndDate()} 
                                    ref={register}
                                />
                            </div>
                        </div>

                        <div className='mt-3 mb-2'>
                            <div className='mb-2'>{t('Priority')}</div>
                            <input type="range" step={1} min={0} max={2} name="priority" className='schedule_priority' defaultValue={props.selectedScheduleEvent ? props.selectedScheduleEvent.priority : 0} id="" ref={register}/>
                        </div>
                    </div>
                    <div>
                        

                        { props.selectedScheduleEvent?.id ? (
                                <div className='row'>
                                    <div className="col-6 d-flex">
                                        { _renderBtnDelete() }
                                    </div>
                                    <div className="col-6 d-flex">
                                        { _renderBtnUpdate() }
                                    </div>
                                </div>
                            ) : (
                                <div className="col-12 d-flex" style={{paddingRight: 0}}>
                                    { _renderBtnSubmit() }
                                </div>
                            )
                        }
                    </div>
                </form>
            </div>
    );

};

export default React.memo(CreateEditSchedule);
