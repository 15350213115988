import Option from "./Option";
import Screen from "./Screen";
export default interface ScreenGroup {
    id: string;
    user_id: string;
    name: string;
    description: string | null;
    touch: string | null;
    screens?: Screen[];
};


export function getScreenGroupOption(screenGroup: ScreenGroup): Option { 
    return {
        value: screenGroup.id,
        type: 'App\\Models\\ScreenGroup',
        label: screenGroup.name,
    }
}
