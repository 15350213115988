import React from "react";
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

// MARK: - Redux
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import history from "./history";

// MARK: - Pages
import {
    PageWrapper,
    Login,
    Register,
    NotFound,
    Dashboard, 
    Schedule, 
    Screen, 
    Library, 
    Administation,
    Profile,
    Playlist,
    ScreenDetail,
    PlaylistDetail,
    Hierarchy,
    EInk,
    EInkDetail,
    Tags
} from "./containers";

// MARK: - Guards
import AuthenticatedRoute from "./guards/authenticatedRoute";
import PrivateRoute from "./guards/privateRoute";
import ScreenGroup from "./containers/ScreenGroup/ScreenGroup";
import ScreenGroupDetail from "./containers/ScreenGroup/ScreenGroupDetail";



// MARK: - Routes
export default () => {
    return(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Switch>

                        <Route exact path="/">
                            <Redirect to="/login" />
                        </Route>

                        <AuthenticatedRoute exact path="/login">
                            <PageWrapper>
                                <Login />
                            </PageWrapper>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact path="/register">
                            <PageWrapper>
                                <Register />
                            </PageWrapper>
                        </AuthenticatedRoute>
                        
                        <PrivateRoute exact path="/dashboard">
                            <PageWrapper>
                                <Dashboard />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/schedule">
                            <PageWrapper>
                                <Schedule />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/screens">
                            <PageWrapper>
                                <Screen />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/screens/:id">
                            <PageWrapper>
                                <ScreenDetail />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/eink">
                            <PageWrapper>
                                <EInk />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/eink/:id">
                            <PageWrapper>
                                <EInkDetail />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/screengroups">
                            <PageWrapper>
                                <ScreenGroup />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/screengroups/:id">
                            <PageWrapper>
                                <ScreenGroupDetail />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/playlists">
                            <PageWrapper>
                                <Playlist />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/playlists/:id">
                            <PageWrapper>
                                <PlaylistDetail />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/library">
                            <PageWrapper>
                                <Library />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/tags">
                            <PageWrapper>
                                <Tags />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/hierarchy" action="user.store">
                            <PageWrapper>
                                <Hierarchy />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/admin">
                            <PageWrapper>
                                <Administation />
                            </PageWrapper>
                        </PrivateRoute>
                        <PrivateRoute exact path="/profile">
                            <PageWrapper>
                                <Profile />
                            </PageWrapper>
                        </PrivateRoute>
                    
                        <Route path="*">
                            <PageWrapper>
                                <NotFound />
                            </PageWrapper>
                        </Route>

                    </Switch>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    );
}

