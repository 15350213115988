import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag from '../../models/Tag';

interface TagItemProps {
    tag: Tag;
    openModal: (tag: Tag) => void;
    openDeleteModal: (tag: Tag) => void;
};

const TagItem: React.FC<TagItemProps> = (props: TagItemProps) => {

    const [t] = useTranslation();

    return(
        <div className="card mb-3">
            <div className="card-body screen-item">
                <div className="screen-item__playing">
                    <i className="iconsminds-tag"></i>
                    <span className="ml-2">{ props.tag.name }</span>
                </div>
                <div className="screen-item__playing">
                    <span className="ml-2">{ props.tag.description }</span>
                </div>
                
                <div className="screen-item__options">
                    <a className="screen-item__name" style={{ fontSize: 'larger' }} onClick={() => props.openModal(props.tag)}>
                        <i className="iconsminds-pen pointer"></i>
                    </a>
                    <a className="screen-item__name" style={{ fontSize: 'larger' }} onClick={() => props.openDeleteModal(props.tag)}>
                    <i className="ml-2 color-secondary-color iconsminds-close pointer"></i>
                    </a>
                </div>
            </div>
         </div>
    );

};

export default React.memo(TagItem);
