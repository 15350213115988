import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';

import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import ScreenService from '../../services/use_case/screen/ScreenService';
import ApiResponse from '../../services/ApiResponse';
import { ScreenInputs } from '../Screen/Screen';
import Screen from '../../models/Screen';

import { 
    CactusLabelInput, 
    DashboardTitle, 
    DashboardWrapper, 
    ScreenPairCode, 
    ModalDeleteConfirm, 
    CactusModal,
    CreatableTagsSelect,
    CreateTagForm
} from '../../components';

import { getOption } from '../../models/Tag';
import Option from '../../models/Option';

import TagsService from '../../services/use_case/tags/TagsService';
import Tag from '../../models/Tag';

interface ScreenDetailProps {};

const ScreenDetail: React.FC<ScreenDetailProps> = (props: ScreenDetailProps) => {

    const [t] = useTranslation();

    const _screenService:ScreenService = new ScreenService()
    const _tagsService:TagsService = new TagsService()

    let { id: screenId } = useParams<{id:string}>() 
    const [isLoading, setisLoading] = useState(false)
    const [isUpdatingForm, setisUpdatingForm] = useState(false)
    const [deletingScreen, setdeletingScreen] = useState(false)
    const [isCreatingTag, setisCreatingTag] = useState(false)
    const [screen, setscreen] = useState<Screen>()
    const [title, settitle] = useState<string>(t('Screen'))

    const [selectedTags, setSelectedTags] = useState<Option[]>([]);

    let history = useHistory()
    
    const { handleSubmit, register, errors, reset } = useForm<ScreenInputs>({
        defaultValues: {
            ...screen
        }
    });
    const textValidator = register(useFormValidatorText());

    useEffect(() => {
        _getScreen(screenId)
        return () => {
            _screenService.cancelRequest()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getScreen = async( id:string ) => {
        setisLoading(true)

        try {
            const screenResponse:AxiosResponse<ApiResponse<Screen>> = await _screenService.show(id);
            const screen:Screen = screenResponse.data.data!
            setisLoading(false)
            setscreen(screen)
            setSelectedTags(screen.tags.map(tag => getOption(tag)))
            reset(screen)
            settitle(t('Screen') + ': ' + screen?.name)
        } catch (error) {
            history.replace('/screens')
        }
    }

    const _deleteScreen = async() => {
        setisUpdatingForm(true)
        try {
            await _screenService.delete(screenId)
            setisUpdatingForm(false)
            setdeletingScreen(false)
            history.replace('/screens')
        } catch (error) {
            setisUpdatingForm(false)
        }
    }

    const _handleOnSubmit =  async(data:FormData) => {

        setisUpdatingForm(true)
        try {
            const updateResponse:AxiosResponse<ApiResponse<Screen>> = await _screenService.update(screenId, data)
            console.log('updateResponse', updateResponse)
            setisUpdatingForm(false)
            setscreen(updateResponse.data.data!)
            settitle(t('Screen') + ': ' + updateResponse.data.data!.name)
        } catch(error) {
            setisUpdatingForm(false)
        }
    }

    const _handleCreateTag = async (data: FormData) => {
        setisCreatingTag(true)
        try {
            const updateResponse:AxiosResponse<ApiResponse<Tag>> = await _tagsService.store(data)
            console.log('updateResponse', updateResponse)
            setisCreatingTag(false)
        } catch(error) {
            setisCreatingTag(false)
        }
        
    }
    
    const _handleSyncTags =  async(json:any) => {

        setisUpdatingForm(true)
        try {
            const selectedTagsIds = selectedTags.map(tag => tag.value);
            json["tag_ids"] = selectedTagsIds;
            const updateResponse:AxiosResponse<ApiResponse<Screen>> = await _screenService.syncTags(screenId, json)
            setisUpdatingForm(false)
        } catch(error) {
            setisUpdatingForm(false)
        }
    }

    const _renderPairCode = () => {
        return (
            <div className="col-md-6 mx-auto">
                <div className="card">
                    <div className="card-body">
                        <ScreenPairCode paircode={screen?.pair_code} />
                        <div className="text-center">
                            { _renderDeleteButton() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const _renderScreenDetailBody = () => {
        return (
            
            screen?.paircode_linked ? _renderScreenForm() : _renderPairCode()

        )
    }

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
                disabled={isUpdatingForm ?? false}
            >
                {t('Update_screen')}
            </button>
        );
    }

    const _renderDeleteButton = () => {
        return (
            <button 
                type="submit"
                onClick={ () => setdeletingScreen(true) }
                className="btn btn-cactus--red mt-2"
                disabled={isUpdatingForm ?? false}
            >
                {t('Delete_screen')}
            </button>
        )
    }

    const _renderBtnSyncTags = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
            >
                {t('Update_tags')}
            </button>
        );
    }

    const _renderBtnCreateTag = () => {
        return (
            <button 
                className="btn btn-success mt-1"
                type='button'
                onClick={ () => setisCreatingTag(true) }
            >
                {t('New_tag')}
            </button>
        );
    }
    
    const _renderScreenForm = () => {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(_handleOnSubmit)} >
                            <div className="row">
                                <div className="col-sm-6">
                                    <CactusLabelInput
                                        labelTitle={t('Name')}
                                        inputAttributes={{
                                            id: "name",
                                            name: "name",
                                            type:  "text",
                                            placeholder: t('Name'),
                                            autoComplete: "name",
                                            ref: textValidator,
                                        }}
                                        errorMessage={errors.name ? errors.name.message : null}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <CactusLabelInput
                                        labelTitle={t('License_id')}
                                        inputAttributes={{
                                            name: "hardware_key",
                                            disabled: true,
                                            classprops: 'disabled',
                                            ref: register,
                                        }}
                                        errorMessage={errors.name ? errors.name.message : null}
                                    />
                                </div>
                                <div className="col-12 text-right">
                                    { _renderBtnSubmit() }
                                </div>
                            </div>
                        </form>

                        <div className="col-12 text-right">
                            { _renderDeleteButton() }
                        </div>
                        
                    </div>
                </div>

                <div className="card mt-3">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(_handleSyncTags)} >
                            <div className="row">
                                <div className="col-sm-6">
                                    <CreatableTagsSelect
                                        value={selectedTags}
                                        onChange={(selectedOption: Option[]) => {
                                            setSelectedTags(selectedOption);
                                        }}
                                        cache={isCreatingTag}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    { _renderBtnCreateTag() }
                                </div>
                                <div className="col-12 text-right">
                                    { _renderBtnSyncTags() }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <CactusModal 
                    show={isCreatingTag}
                    title={ t('Create_tag') }
                    onClose={() => {
                        setisCreatingTag(false)
                    }}
                    minWidth="500px"
                >
                    <div className='row'>
                        <CreateTagForm
                            handleOnSubmit={_handleCreateTag}
                        />
                    </div>
                </CactusModal>
            </>
        )
    }
    

    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-6">
                    <DashboardTitle title={title} isLoading={isLoading}  />
                </div>
                <div className="col-12">
                    {
                        !isLoading && _renderScreenDetailBody()
                    }
                </div>
            </div>

            <ModalDeleteConfirm 
                show={deletingScreen}
                title={t('Delete_screen')}
                onCloseFunction={setdeletingScreen}
                onCloseFunctionParameter={false}
                entityTranslation={t('screen')}
                buttonLoading={isUpdatingForm}
                onClickFunctionButton={_deleteScreen}
                buttonText={t('Delete')}
            />
            
        </DashboardWrapper>
    );
};

export default React.memo(ScreenDetail);
