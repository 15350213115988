import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import Screen from "../../models/Screen";
import { IconLoader } from '../../assets/icons/IconLoader';
import QueryParams from '../../models/QueryParams';
import Option from '../../models/Option';

interface AttachScreenProps {
    disabled: boolean;
    screenGroupId: string;
    tags?: Option[];
    onSubmit: (screenIds: Screen[]) => void;
    maxHeight?: string;
    minHeight?: string;
    overflow?: string;
    onChangeTags?: (tags: Option[]) => void;
    _fetchScreens: (params?: QueryParams) => void;
    screens: Screen[];
    pagination: any;

};

const AttachScreen: React.FC<AttachScreenProps> = (props: AttachScreenProps) => {

    const [t] = useTranslation();
    const [selectedScreen, setSelectedScreen] = useState<Screen[]>([]);

    useEffect(() => {
        let params: QueryParams = {
            id: props.screenGroupId,
            page: 1,
        };
        props._fetchScreens(params);

    }, []);

    const _renderScreens = () => {

        let screensToAttach: JSX.Element[] = props.screens.map((screen: Screen, index: number) => {

            const handleCheckboxChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
                const checkbox = e.currentTarget.querySelector<HTMLInputElement>('input[type="checkbox"]');
                if (!checkbox) return;
                checkbox.checked = !checkbox.checked;
                
                const checked = checkbox.checked;
                const value = checkbox.value;
                const screen = props.screens.find(screen => screen.id === value);

                if(!screen) return;
                setSelectedScreen(prev =>
                    checked ? [...prev, screen] : prev.filter(screen => screen.id !== value)
                );
            };
        
            return(
                <div key={screen.id} className="card mb-3">
                    <a
                        href="!#"
                        className={`card-body screen-item ${selectedScreen.some(s => s.id === screen.id) ? 'checked' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            handleCheckboxChange(e);
                        }}
                    >
                        <div className="screen-item__name" >
                            <i className="iconsminds-monitor"></i>
                            <span className="ml-2">{ screen.name }</span>
                        </div>
                        <div className="screen-item__options">
                            <input type='checkbox' style={{display: 'none'}}
                                value={screen.id}
                                onChange={(e) => {
                                    e.preventDefault();
                                }}
                                checked={selectedScreen.some(s => s.id === screen.id)}
                            />
                        </div>
                    </a>
                </div>
            )
        }) ?? [];

        if(screensToAttach.length === 0) {
            return(
                <div>
                    <div className="mb-4">
                        {t('No Result')}
                    </div>
                </div>
            )
        }

        return(
            <>
                <InfiniteScroll
                    pageStart={props.pagination?.from}
                    loadMore={() => {
                        let params: QueryParams = {
                            id: props.screenGroupId,
                            page: props.pagination?.current_page ? props.pagination?.current_page + 1 : 1,
                        }
                        props._fetchScreens(params)
                    }}
                    hasMore={props.pagination?.next_page_url !== null}
                    loader={
                        <div key="loading" className="d-flex justify-content-center">
                            <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                        </div>
                    }
                    useWindow={false}
                >
                    <div className="col-12">
                        {screensToAttach}
                    </div>
                </InfiniteScroll>
            </>
        );

    };

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
                disabled={props.disabled}
                onClick={() => {
                    props.onSubmit(selectedScreen);
                }}
            >
                {t('Attach_screens')}
            </button>
        );
    }

    return(
        <>
            <div style={{maxHeight: props.maxHeight, minHeight: props.minHeight, overflow:props.overflow}}>
                {_renderScreens()}
            </div>

            <div className="col-12 text-right mt-2">
                { _renderBtnSubmit() }
            </div>
        </>
    );

};

export default React.memo(AttachScreen);
