import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import ScreenGroupService from '../../services/use_case/screengroup/ScreenGroupService';
import ScreenResponse from '../../services/use_case/screen/ScreenResponse';
import ApiResponse from '../../services/ApiResponse';
import { ScreenGroupInputs } from './ScreenGroup';
import ScreenGroup from '../../models/ScreenGroup';
import ScreenModel from '../../models/Screen';

import { 
    AttachScreen, 
    CactusModal, 
    DashboardTitle, 
    DashboardWrapper, 
    ModalDeleteConfirm,
    SearchDebounce
} from '../../components';
import ScreenItemSkeleton from '../../components/ScreenItem/ScreenItemSkeleton';
import Option from '../../models/Option';
import CreatableTagsSelect from '../../components/CreatableTagsSelect';
import QueryParams from '../../models/QueryParams';

interface FilterOptions {
    search?: string;
    tags?: Option[];
}

interface ScreenGroupDetailProps {};

const ScreenGroupDetail: React.FC<ScreenGroupDetailProps> = (props: ScreenGroupDetailProps) => {

    const [t] = useTranslation();

    const _screenGroupService:ScreenGroupService = new ScreenGroupService()

    let { id: screenGroupId } = useParams<{id:string}>() 
    const [isLoading, setisLoading] = useState(false)
    const [isAttachingForm, setisAttachingForm] = useState(false)
    const [screenGroup, setscreenGroup] = useState<ScreenGroup>()
    const [attachedScreens, setAttachedScreens] = useState<ScreenModel[]>([]);
    const [title, settitle] = useState<string>(t('Screen Group'))
    const [search, setSearch] = useState<string>('');
    const [attachingScreen, setAttachingScreen] = useState(false);
    const [detachingScreen, setDetachingScreen] = useState(false);
    const [isDetachingScreenForm, setisDetachingScreenForm] = useState(false);
    const [ScreenIdToDelete, setScreenIdToDelete] = useState<string | null>(null);
    const [selectedTags, setSelectedTags] = useState<Option[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [pagination, setPagination] = useState<any>();
    const [screens, setScreens] = useState<ScreenModel[]>([]);

    let history = useHistory()

    const { reset } = useForm<ScreenGroupInputs>({
        defaultValues: {
            ...screenGroup
        }
    });

    useEffect(() => {
       _fetchScreenInScreenGroup();
       setSelectedTags([]);
       setSearchText('');
    }, [])

    const _getScreenGroup = async( id:string ) => {
        setisLoading(true)

        try {
            const screenGroupResponse:AxiosResponse<ApiResponse<ScreenGroup>> = await _screenGroupService.show(id);
            const screenGroup:ScreenGroup = screenGroupResponse.data.data!
            setisLoading(false)
            setscreenGroup(screenGroup)
            setAttachedScreens(screenGroup.screens ?? [])
            reset(screenGroup)
            settitle(t('Screen Group') + ': ' + screenGroup?.name)
        } catch (error) {
            history.replace('/screengroups')
        }

    }

    const _detachScreen = async() => {
        if (!ScreenIdToDelete) return;
        try {
            const response = await _screenGroupService.detachScreen(screenGroupId, [ScreenIdToDelete])
            if (response.status === 200) {
                setAttachedScreens(prevScreens => prevScreens.filter(screen => screen.id !== ScreenIdToDelete));
            }
            setisDetachingScreenForm(false)
            setDetachingScreen(false)
        } catch (error) {
            setisDetachingScreenForm(false)
            setDetachingScreen(false)
        }
    }

    const _fetchScreenInScreenGroup = (page?: number) => {
        _getScreenGroup(screenGroupId)
        return () => {
            _screenGroupService.cancelRequest()
        }
    }

    const updateFilters = ({search, tags}: FilterOptions) => {
    
        setSearchText(search ?? searchText);
        setSelectedTags(tags ?? selectedTags);
    
        fetchFilteredScreens(search ?? searchText, tags ?? selectedTags);
    };

    const fetchFilteredScreens = (search: string, tags: Option[]) => {
        let params: QueryParams = {
            id: screenGroupId,
            page: 1,
            search: search,
            tags: tags?.map(tag => tag.label) ?? []
        };

        setScreens([]);
        _fetchScreens(params);
    };

    const _fetchScreens = async (params?: QueryParams) => {
        if(!params) return;
        try {
            const screenGroupServiceResponse: AxiosResponse<ScreenResponse> = await _screenGroupService.fetchScreensNotInScreenGroup(params);
            console.log ("screenGroupServiceResponse", screenGroupServiceResponse)
            setPagination(screenGroupServiceResponse.data.data);
            const screenGroupServiceData: ScreenModel[] = screenGroupServiceResponse.data.data?.data ?? [];
            if(params?.page == 1) {
                setScreens(screenGroupServiceData);
                return;
            }
            setScreens((prevScreens) => prevScreens.concat(screenGroupServiceData));
        }
        catch (error) {
        }
    }

    const _handleOpenAttachScreen = () => {
        setisAttachingForm(false)
        setAttachingScreen(true)
        setSelectedTags([]);
        setSearchText('');
    }

    const _handleOpenDetachScreen = (screen_id: string) => {
        setScreenIdToDelete(screen_id)
        setDetachingScreen(true)
    }

    const _handleAttachScreen = async (selectedScreen: ScreenModel[]) => {
        try {
            const selectedScreenIds = selectedScreen.map(screen => screen.id);
            const response = await _screenGroupService.attachScreen(screenGroupId, selectedScreenIds);
            
            if(response.status >= 200 && response.status < 300) {
                setAttachedScreens(prevScreens => [...prevScreens, ...selectedScreen]);
            }
        }
        catch (error) {
            
        }
        finally {
            setAttachingScreen(false);
        }
    };

    const _renderAttachedScreens = () => {

        if(isLoading) {
            return _renderSkeletonLoading();
        }

        //search screen
        const filteredScreens = attachedScreens.filter((screen: ScreenModel) =>
            screen.name.toLowerCase().includes(search.toLowerCase())
        );

        let screens = filteredScreens.map((screen: ScreenModel, index: number) => {
            return(
                <div key={screen.id} className="col-2">
                    <div className="card mb-3">
                        <div className={`card-body screen-item`}>
                            <i className="iconsminds-monitor"></i>
                            <span className="ml-2">{ screen.name }</span>
                            <a onClick={ () => _handleOpenDetachScreen(screen.id)}>
                                <i className="color-secondary-color iconsminds-close pointer"></i>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }) ?? [];

        if(screens.length === 0) {
            return(
                <div className='col-12'>
                    {t('No screens attached')}
                </div>
            )
        }

        return(
            <>
                {screens}
            </>
        );
    
    }

    const _renderSkeletonLoading = () => {
        return Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mb-3">
                    <ScreenItemSkeleton />
                </div>
            );
        });
    }

    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-12 col-md-6">
                    <DashboardTitle 
                        title={title}
                        isLoading={isLoading}
                        withRefreshIcon={!isLoading}
                        refreshIconCallBack={() => {
                            _fetchScreenInScreenGroup(1);
                        }}
                    />

                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => setSearch(text)}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex mt-1">
                        <button
                            className="btn btn-cactus ml-auto"
                            disabled={isLoading}
                            onClick={ () => _handleOpenAttachScreen() }
                        >
                            { t('Attach_screens') }
                        </button>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                {_renderAttachedScreens()}
            </div>

            <CactusModal 
                show={attachingScreen}
                title={ t('Attach_screens') }
                onClose={() => {
                    setAttachingScreen(false)
                }}
                minWidth="500px"
            >
                <div className="mb-3">
                    <span className="form__label mb-1">{t('Search_by_name')}</span>
                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        debounceFinish={(text: string) => updateFilters({ search: text })}
                    />
                </div>
                <div className="mb-4">
                    <span className="form__label mb-1">{t('Search_by_tags')}</span>
                    <CreatableTagsSelect
                        value={selectedTags}
                        onChange={(selectedOption: Option[]) => updateFilters({ tags: selectedOption })}
                        closeMenuOnSelect={true}
                    />
                </div>
                <AttachScreen
                    maxHeight='55vh'
                    minHeight='55vh'
                    overflow='auto'
                    disabled={isAttachingForm ?? false}
                    onSubmit={(selectedScreen: ScreenModel[]) => _handleAttachScreen(selectedScreen)}
                    screenGroupId={screenGroupId}
                    tags={selectedTags}
                    _fetchScreens={_fetchScreens}
                    screens={screens}
                    pagination={pagination}
                />

            </CactusModal>
            <ModalDeleteConfirm 
                show={detachingScreen}
                title={t('Detach_screen')}
                onCloseFunction={setDetachingScreen}
                onCloseFunctionParameter={false}
                overrideEntityTranslationAbove={ t('Are_you_sure_to_detach_current_f_') }
                entityTranslation={t('screen')}
                buttonLoading={isDetachingScreenForm}
                onClickFunctionButton={_detachScreen}
                buttonText={t('Detach')}
            />
        </DashboardWrapper>
    );

};

export default React.memo(ScreenGroupDetail);
