import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import ScreenResponse from "./ScreenResponse";
import ScheduleResponse from "../schedule/ScheduleResponse";
import Screen from "../../../models/Screen";
import ApiResponse from "../../ApiResponse";

export default class ScreenService extends BaseService {

    fetch(page: number = 1, search: string = ""): Promise<AxiosResponse<ScreenResponse>> {
        let uri: string = `/screens?page=${page}`;

        if(search !== "") uri += `&q=${search}`;

        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    schedulesByScreenId(id: string): Promise<AxiosResponse<ApiResponse<ScheduleResponse>>> {
        let uri: string = `/screens-schedules/${id}`;
        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    store(formData: FormData): Promise<AxiosResponse<ApiResponse<Screen>>> {
        return this.axios.post(`/screens`, formData);
    }

    show(id: string): Promise<AxiosResponse<ApiResponse<Screen>>> {
        return this.axios.get(`/screens/${id}`, this.getCancelTokenStructure() );
    }

    update(id: string, screen: Screen | FormData): Promise<AxiosResponse<ApiResponse<Screen>>> {
        return this.axios.put(`/screens/${id}`, screen);
    }

    delete(id: string): Promise<AxiosResponse<ApiResponse<Screen>>> {
        return this.axios.delete(`/screens/${id}`);
    }

    syncTags(id: string, tags: FormData): Promise<AxiosResponse<ApiResponse<Screen>>> {
        return this.axios.post(`/screens/${id}/sync-tag`, tags);
    }
}
