import Playlist from "./Playlist";
import Option from "./Option";
import Tag from "./Tag";
export default interface Screen {
    id: string;
    user_id: string;
    name: string;
    hardware_key: string | null;
    pair_code: string | null;

    paircode_linked: boolean | null;

    linked?: Playlist | null;
    linked_type?: string | null;
    linked_id?: string | null;

    tags: Tag[]
};

export function isScreenActive(screen: Screen) {
    return screen.hardware_key;
}

export function isScreenActiveAndSetPlaylist(screen: Screen) {
    return screen.linked_id && screen.hardware_key;
}

export function isScreenNotActive(screen: Screen) {
    return screen.pair_code && screen.hardware_key === null;
}

export function isScreenLinked(screen: Screen) {
    return screen.paircode_linked;
}

export function getScreenOption(screen: Screen): Option { 
    return {
        value: screen.id,
        type: 'App\\Models\\Screen',
        label: screen.name,
    }
}
