import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Option from '../../models/Option';
import PlaylistService from '../../services/use_case/playlist/PlaylistService';
import Playlist, { getOption } from '../../models/Playlist';
import { AsyncPaginate } from 'react-select-async-paginate';

const selectStyles = {
    control: (styles: any, state: any) => ({ 
        ...styles,
        backgroundColor: '#232223',
        border: '1px solid #424242',
        boxShadow: state.isFocused ? null : null,
        fontSize: '.9rem',
        "&:hover": {
            borderColor: "#c0702f",
        },
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        // width: state.selectProps.width,
        backgroundColor: state.isDisabled ? '#969696' : '#232223',
        border: '1px solid #424242',
        color: '#969696',
        fontSize: '.9rem',
    }),
    option: (styles: any, meta: any) => {
        const { data, isDisabled/*, isFocused, isSelected*/ } = meta;
        const color = data.color;
        return {
            ...styles,
            backgroundColor: '#232223',
            cursor: isDisabled ? 'not-allowed' : 'default',
            color,
            "&:hover": {
                backgroundColor: "#c0702f",
                opacity: ".8",
                cursor: "pointer",
                color: "white",
            },
            "&:active": {
                backgroundColor: "transparent",
            },
        };
    },
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { 
            ...provided,
            opacity,
            transition,
            color: '#969696',
        };
    },
    indicatorSeparator: (provided: any, state: any) => {
        return { 
            ...provided,
            backgroundColor: '#424242'
        };
    },
    input: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: '#969696',
        }
    },
};

interface SelectAsyncPaginateProps {
    value: any;
    onChange: any;
};

const SelectAsyncPaginate: React.FC<SelectAsyncPaginateProps> = (props: SelectAsyncPaginateProps) => {

    const [t] = useTranslation();

    let playlistService: PlaylistService | null = null;

    const _fetchPlaylists = async (search:string, prevOptions: any = null , { page }: { page: number }) => {
        try {

            playlistService = new PlaylistService();
            let response: any = await playlistService.fetch(page, search);
            const responseData = response.data?.data;

            let playlists: Playlist[] = response.data?.data?.data ?? [];
    
            let data = playlists.map((playlist: Playlist) => getOption(playlist) );

            return {
                options: data,
                hasMore: responseData?.last_page > page,
                additional: {
                    page: responseData.current_page + 1
                },
            }
        }
        catch(error) {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 1
                }
            };
        }
    }

    return(
        <>
          <AsyncPaginate
            {...props}
            loadOptions={_fetchPlaylists}
            styles={selectStyles}
            isSearchable={true}
            noOptionsMessage={() => t('No Result')}
            loadingMessage={() => t('Loading...')}
            placeholder="-"
            debounceTimeout={500}
            additional={{ page: 1, }}
          />
        </>
    );

};

export default React.memo(SelectAsyncPaginate);
