import React, { useState, ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Option from '../../models/Option';
import TagsService from '../../services/use_case/tags/TagsService';
import Tag, { getOption } from '../../models/Tag';

import { AsyncPaginate } from "react-select-async-paginate";


const selectStyles = {
    control: (styles: any, state: any) => ({ 
        ...styles,
        backgroundColor: '#232223',
        border: '1px solid #424242',
        boxShadow: state.isFocused ? null : null,
        fontSize: '.9rem',
        "&:hover": {
            borderColor: "#c0702f",
        },
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        // width: state.selectProps.width,
        backgroundColor: state.isDisabled ? '#969696' : '#232223',
        border: '1px solid #424242',
        color: '#969696',
        fontSize: '.9rem',
    }),
    option: (styles: any, meta: any) => {
        const { data, isDisabled/*, isFocused, isSelected*/ } = meta;
        const color = data.color;
        return {
            ...styles,
            backgroundColor: '#232223',
            cursor: isDisabled ? 'not-allowed' : 'default',
            color,
            "&:hover": {
                backgroundColor: "#c0702f",
                opacity: ".8",
                cursor: "pointer",
                color: "white",
            },
            "&:active": {
                backgroundColor: "transparent",
            },
        };
    },
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { 
            ...provided,
            opacity,
            transition,
            color: '#969696',
        };
    },
    indicatorSeparator: (provided: any, state: any) => {
        return { 
            ...provided,
            backgroundColor: '#424242'
        };
    },
    input: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: '#969696',
        }
    },
    multiValue: (styles: any, state: any) => {
        return {
            ...styles,
            backgroundColor: '#c0702f',
            color: '#d0d0d0',
            borderRadius: 5,
            fontWeight: 'bold'
        }
    },
    multiValueLabel: (styles: any, state: any) => {
        return {
            ...styles,
            backgroundColor: '#c0702f',
            color: '#d0d0d0',
            borderRadius: 5,
            fontWeight: 'bold'
        }
    },
    multiValueRemove: (styles: any, state: any) => {
        return {
            ...styles,
            backgroundColor: '#c0702f',
            color: '#d0d0d0',
            borderRadius: 5,
            "&:hover": {
                backgroundColor: "#9f5d27",
                cursor: "pointer",
                color: "#d0d0d0",
            },
        }
    },
    
};

interface CreatableTagsSelectProps {
    value: any;
    onChange: any;
    cache?: any;
    closeMenuOnSelect?: boolean;

    //onCreateTag: (tagName: string) => void;
};

const CreatableTagsSelect: React.FC<CreatableTagsSelectProps> = (props: CreatableTagsSelectProps) => {

    const [t] = useTranslation();

    let tagsService: TagsService

    const _fetchTags = async (search:string, prevOptions: any = null , { page }: { page: number }) => {
        try {

            tagsService = new TagsService();
            let response: any = await tagsService.fetch(page, search);
            const responseData = response.data?.data;

            let tags: Tag[] = response.data?.data?.data ?? [];

            let data = tags.map((tag: Tag) => getOption(tag) );

            return {
                options: data,
                hasMore: responseData?.last_page > page,
                additional: {
                    page: responseData.current_page + 1
                },
            }
        }
        catch(error) {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 1
                }
            };
        }
    }

    return (
        <>
            <AsyncPaginate
                {...props}
                loadOptions={_fetchTags}
                styles={selectStyles}
                isSearchable={true}
                isMulti={true}
                noOptionsMessage={() => t('No Result')}
                loadingMessage={() => t('Loading...')}
                placeholder={t('Select_a_tag')}
                debounceTimeout={500}
                additional={{ page: 1, }}
                cacheUniqs={[props.cache]}
            />
        </>
    );

};

export default React.memo(CreatableTagsSelect);