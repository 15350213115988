import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { CactusModal, DashboardTitle, DashboardWrapper, SearchDebounce, ModalDeleteConfirm, Pagination } from '../../components';
import Screen, { getScreenOption } from '../../models/Screen';
import * as actionCreator from "../../actions";
import ScheduleModel from "../../models/Schedule";
import ScreenService from "../../services/use_case/screen/ScreenService";

import ApiResponse from '../../services/ApiResponse';
import { AxiosResponse } from 'axios';

import Option from "../../models/Option";
import PlaylistService from '../../services/use_case/playlist/PlaylistService';
import Playlist, { getOption } from '../../models/Playlist';

import useFormValidatorText from '../../hooks/validators/use-form-validator-text';

import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import CustomCalendarToolBar from '../../components/CustomCalendarToolBar';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

import ScheduleService from '../../services/use_case/schedule/ScheduleService';
import ScreenGroup, {getScreenGroupOption} from '../../models/ScreenGroup';
import ScreenGroupService from '../../services/use_case/screengroup/ScreenGroupService';

import InfiniteScroll from 'react-infinite-scroller';
import { IconLoader } from '../../assets/icons/IconLoader';
import CustomEvent from '../../components/CustomEvent';

import CreateEditSchedule from '../../components/CreateEditSchedule';

let lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";

moment.locale(lang);


let screenService: ScreenService;
let screenGroupService: ScreenGroupService;

export interface ScheduleEvent extends Event {
    id: string;
    linked_id: string;
    priority: number;
    device_id: string;
    device_type: string;
    device_name: string;
}
export interface ScheduleInputs {
    name: string;
    devide_id: string;
    linked_id: string;
    start_date: Date;
    end_date: Date;
    priority: number;
}

interface ScheduleProps {};

const Schedule: React.FC<ScheduleProps> = (props: ScheduleProps) => {

    lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";

    const [t] = useTranslation();
    const [search, setSearch] = useState<string>("");

    const [screenSelected, setScreenSelected] = useState<Screen|null>(null);
    const [screengroupSelected, setScreengroupSelected] = useState<ScreenGroup|null>(null);
    const [schedules, setSchedules] = useState<ScheduleModel[]>([]);
    const [scheduleDate, setScheduleDate] = useState(new Date());
    
    const [scheduleForm, setScheduleForm] = useState(false);
    const [updatingSchedule, setUpdatingSchedule] = useState(false);
    const [deletingScheduleForm, setDeletingScheduleForm] = useState(false);

    const [selectedScheduleEvent, setSelectedScheduleEvent] = useState<ScheduleEvent | null>(null);

    const [defaultPlaylistOption, setPlaylistDefaultOption] = useState<Option[]>([]);
    const [playlistValue, setPlaylistValue] = useState<Option | null>(null);

    const [defaultScreenOption, setScreenDefaultOption] = useState<Option[]>([]);

    const [PanelNavLink, setPanelNavLink] = useState<number>(0);
    const [showSchedule, setShowSchedule] = useState<boolean>(false);

    const [isLoadingCreateSchedule] = useState(false);
    const { register } = useForm<ScheduleInputs>();
    const textValidator = register(useFormValidatorText());
    const dispatch = useDispatch();

    const screenReducer = useSelector( (state: any) => state.screenReducer);
    const screenPagination: any = screenReducer?.data;
    const screenData: any = screenPagination?.data ?? [];
    const isLoadingScreen = screenReducer?.isLoading;

    const screengroupReducer = useSelector( (state: any) => state.screenGroupReducer);
    const screengroupPagination: any = screengroupReducer?.data;
    const screengroupData: any = screengroupPagination?.data ?? [];
    const isLoadingScreengroup = screengroupReducer?.isLoadingScreenGroup;

    const defaultView = 'week';

    let playlistService: PlaylistService;

    moment.locale(lang);
    const localizer = momentLocalizer(moment)

    registerLocale('es', es)

    useEffect( () => {
        _fetchScreens(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if(!screenSelected) {
            setSchedules([]);
            return;
        }

        if(!screengroupSelected) {
            setSchedules([]);
            return;
        }

    }, [screenSelected]);

    useEffect(() => {
        if(!scheduleForm) {
            setScreenDefaultOption([])
            //setPlaylistDefaultOption([])
            return;
        }
        //_fetchDefaultOptions();
    }, [scheduleForm])

    const _fetchScreens = (page?: number) => {
        dispatch( actionCreator.screenCancelRequest() );
        dispatch( actionCreator.screenFetch(page, search) );
    }

    const _fetchScreengroups = (page?: number) => {
        dispatch( actionCreator.screenCancelRequest() );
        dispatch( actionCreator.screenGroupFetch(page, search) );
    }

    const fetchscreenSchedule = async (id: string) => {
        screenService?.cancelRequest();
        screenService = new ScreenService();

        try {
            const response = await screenService.schedulesByScreenId(id);
            const screenWithSchedule = response.data?.data?.data ?? [];

            setSchedules(screenWithSchedule)
        }
        catch(error) {}
    }

    const fetchScreengroupSchedule = async (id: string) => {
        screenGroupService?.cancelRequest();
        screenGroupService = new ScreenGroupService();

        try {
            const response = await screenGroupService.schedulesByScreenGroupId(id);
            const screengroupWithSchedule = response.data?.data?.data ?? [];

            setSchedules(screengroupWithSchedule)
        }
        catch(error) {}
    }

    const _handleCreateSchedule = () => {
        setSelectedScheduleEvent(null)
        setScheduleForm(true)
        setPlaylistValue(null)
    }

    const _handleUpdateSchedule = async (scheduleEvent: ScheduleEvent) => {

        let playlistService = new PlaylistService();
        let response: any = await playlistService.show(scheduleEvent.linked_id);

        let data: Playlist = response.data?.data ?? null;
        let playlist = getOption(data);
        
        if(playlist) setPlaylistValue(playlist)

        setScheduleForm(true)
        setSelectedScheduleEvent(scheduleEvent);
    }

    const showScreenSchedule = (screen: Screen) => {
        setScreenSelected(screen);
        setShowSchedule(true);
        fetchscreenSchedule(screen.id);
    }

    const showScreenGroupSchedule = (screengroup: ScreenGroup) => {
        setScreengroupSelected(screengroup);
        setShowSchedule(true);
        fetchScreengroupSchedule(screengroup.id);
    }

    const _renderScreens = () => {

        if(isLoadingScreen) return null;

        let screens: Screen[] = screenData?.map((screen: Screen, index: number) => {

            let screenClassActive = `${screen.id === screenSelected?.id ? 'screen-item--active' : null}`;

            return(
                <div key={screen.id} className="card mb-3" onClick={() => showScreenSchedule(screen)}>
                    <div className={`card-body pointer screen-item ${screenClassActive}`}>
                        <i className="iconsminds-monitor"></i>
                        <span className="ml-2">{ screen.name }</span>
                    </div>
                </div>
            );

        }) ?? [];

        if(screens.length === 0) {
            return(
                <div>
                    {t('No Result')}
                </div>
            )
        }

        return(
            <InfiniteScroll
                pageStart={screenPagination?.from}
                loadMore={() => {
                    _fetchScreens(screenPagination?.current_page ? screenPagination?.current_page + 1 : 1)
                }}
                hasMore={screenPagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                <div className="col-12">
                    {screens}
                </div>
            </InfiniteScroll>
        );

    }

    const _renderScreenGroups = () => {

        if(isLoadingScreengroup) return null;

        let screengroups: ScreenGroup[] = screengroupData?.map((screengroup: ScreenGroup, index: number) => {

            let screenClassActive = `${screengroup.id === screengroupSelected?.id ? 'screen-item--active' : null}`;

            return(
                <div key={screengroup.id} className="card mb-3" onClick={() => showScreenGroupSchedule(screengroup)}>
                    <div className={`card-body pointer screen-item ${screenClassActive}`}>
                        <i className="iconsminds-monitor"></i>
                        <span className="ml-2">{ screengroup.name }</span>
                    </div>
                </div>
            );

        }) ?? [];

        if(screengroups.length === 0) {
            return(
                <div>
                    {t('No Result')}
                </div>
            )
        }
        
        return(
            <InfiniteScroll
                pageStart={screengroupPagination?.from}
                loadMore={() => {
                    _fetchScreengroups(screengroupPagination?.current_page ? screengroupPagination?.current_page + 1 : 1)
                }}
                hasMore={screengroupPagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                <div className="col-12">
                    {screengroups}
                </div>
            </InfiniteScroll>
        );

    }

    const _handleOnSubmit = async(json: any) => {

        let options = null;

        if(screenSelected){
            options = getScreenOption(screenSelected);
        }

        if(screengroupSelected){
            options = getScreenGroupOption(screengroupSelected);
        }

        //json["device_id"] = options?.value ?? "";
        //json["device_type"] = options?.type ?? "";
        json["linked_id"] = playlistValue?.value ?? "";
        json["linked_type"] = playlistValue?.type ?? "";

        json["start_date"] = new Date(json.start_date).toUTCString(); 
        if(json.end_date && json.end_date !== ""){
            json["end_date"] = new Date(json.end_date).toUTCString();
        } else {
            delete json.end_date;
        }

        try {
            if(selectedScheduleEvent?.id && updatingSchedule){
                json["device_id"] = selectedScheduleEvent.device_id ?? "";
                json["device_type"] = selectedScheduleEvent.device_type ?? "";
                let response:AxiosResponse<ApiResponse<ScheduleModel>> = await new ScheduleService().update(selectedScheduleEvent?.id, json);
                const scheduleResponse = response.data.data;
                setSchedules((prevState: ScheduleModel[]) => {
                    prevState.forEach((schedule: ScheduleModel) => {
                        if (schedule.id == selectedScheduleEvent.id && scheduleResponse) {
                            schedule.name = scheduleResponse.name
                            schedule.start_date = scheduleResponse.start_date
                            schedule.end_date = scheduleResponse.end_date
                            schedule.linked_id = scheduleResponse.linked_id
                            schedule.priority = scheduleResponse.priority
                        }
                    });
                    return prevState
                })
            } else {
                json["device_id"] = options?.value ?? "";
                json["device_type"] = options?.type ?? "";
                let response:AxiosResponse<ApiResponse<ScheduleModel>> = await new ScheduleService().store(json);
                const scheduleResponse = response.data.data;
                setSchedules((schedules: ScheduleModel[]) => schedules.concat(scheduleResponse ?? []));
            }
        }
        catch (err){}

        setScheduleForm(false);
    }

    const _deleteSchedule = async() => {

        if(!selectedScheduleEvent) return;

        try {
            let _scheduleService: ScheduleService = new ScheduleService();
            await _scheduleService.delete(selectedScheduleEvent.id)
            setDeletingScheduleForm(false)
            setScheduleForm(false)
            setSchedules((prevState: ScheduleModel[]) => {
                return prevState.filter((state: ScheduleModel) => state.id != selectedScheduleEvent.id);
            })
        } catch (error) {

        }
    }

    const _handleDateChange = (newDate:Date) => {
        setScheduleDate(newDate);
    };

    const handlePlaylistChange = (playlistValue: Option | null) => {
        setPlaylistValue(playlistValue);
    };

    const _renderSchedule = () => {

        if(!showSchedule) return null;

        const events: ScheduleEvent[] = schedules.map((schedule: ScheduleModel) => _getEvent(schedule));

        return(
            <div className='schedule-view sticky-top' style={{top: '90px'}}>
                <div className='schedule__toolbar mb-3'>
                    <button 
                        className='btn btn-success'
                        disabled={isLoadingScreen}
                        onClick={ () => _handleCreateSchedule() }
                    >
                        {t('New_schedule')}
                    </button>
                </div>
                <Calendar
                    localizer={localizer}
                    events={events}
                    date={scheduleDate}
                    drilldownView={null}
                    startAccessor="start"
                    endAccessor="end"
                    showMultiDayTimes={true}
                    views={['week', 'day']} 
                    defaultView='week'
                    timeslots={4}
                    dayLayoutAlgorithm="no-overlap"
                    onSelectEvent={_handleUpdateSchedule}
                    components={{event: CustomEvent, toolbar: CustomCalendarToolBar}}
                    onNavigate={_handleDateChange}
                    eventPropGetter={(event: ScheduleEvent) => {
                        let className = 'rbc-event';

                        if (event.device_type == 'App\\Models\\ScreenGroup') {
                            className = 'rbc-screengroup-event';
                        }

                        return {
                            className,
                        };
                    }}
                />
            </div>
        );

    }

    const _getEvent = (schedule: ScheduleModel): ScheduleEvent => {

        let deviceName = '';

        if(schedule.device_type == 'App\\Models\\Screen') {
            let screen = screenData.find((screen: Screen) => screen.id == schedule.device_id);
            if(screen) {
                deviceName = screen.name;
            }
        } else if (schedule.device_type == 'App\\Models\\ScreenGroup') {
            let screengroup = screengroupData.find((screengroup: ScreenGroup) => screengroup.id == schedule.device_id);
            if(screengroup) {
                deviceName = screengroup.name;
            }
        }

        let event: ScheduleEvent = {
            id: schedule.id,
            title: schedule.name,
            device_id: schedule.device_id,
            device_type: schedule.device_type,
            device_name: deviceName,
            linked_id: schedule.linked_id,
            priority: schedule.priority,
            start: moment(schedule.start_date).toDate(),
        }

        if(schedule.end_date) {
            event['end'] = moment(schedule.end_date).toDate();
        } else {
            event['end'] = moment('9999-12-31').toDate();
        }

        return event;
    }

    const _renderPanelBody = () => {

        if(PanelNavLink === 0) {
            return(
                <>
                    {_renderScreens()}
                </>
            );
        }
        else if(PanelNavLink === 1) {
            return(
                <>
                    {_renderScreenGroups()}
                </>
            );
        }

        return null;

    }

    return(
        <DashboardWrapper>
            <DashboardTitle title={t('Schedule')} isLoading={isLoadingScreen}/>

            <div className="schedule">
                <div className="schedule__filter">

                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => {
                            setSearch(text);
                            setScreenSelected(null);
                        }}
                        debounceFinish={(text: string) => _fetchScreens(1)}
                    />

                </div>

                <div className="schedule__screens">
                    <div className="navigation">
                        <nav className="nav nav-pills nav-fill">
                            <a 
                                className={`nav-link text-center ${PanelNavLink === 0 ? "active" : null}`}
                                href="!#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPanelNavLink(0);
                                    setShowSchedule(false);
                                    setScreengroupSelected(null)
                                }}
                            >
                                {t('Screens')}
                            </a>  
                            <a 
                                className={`nav-link text-center ${PanelNavLink === 1 ? "active" : null}`}
                                href="!#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPanelNavLink(1);
                                    setShowSchedule(false);
                                    setScreenSelected(null)
                                    _fetchScreengroups(1);
                                }}
                            >
                                {t('Screen Groups')}
                            </a>
                        </nav>
                    </div>
                    <div className="mt-3">
                        {_renderPanelBody()}
                    </div>
                </div>

                <div className='schedule__content mt-1'>
                    {_renderSchedule()}
                </div>

                <CactusModal
                    show={scheduleForm}
                    title={ selectedScheduleEvent?.id ? t('Edit_schedule') : t('New_schedule') }
                    onClose={() => {
                        setScheduleForm(false)
                        //setSelectedScheduleEvent(null)
                    }}
                    minWidth="500px"
                    overflow='visible'
                >
                    <div>
                    <CreateEditSchedule
                        disabled={false}
                        selectedScheduleEvent={selectedScheduleEvent}
                        playlistValue={playlistValue}
                        setUpdatingSchedule={setUpdatingSchedule}
                        setDeletingScheduleForm={setDeletingScheduleForm}
                        onPlaylistChange={handlePlaylistChange}
                        onSubmit={_handleOnSubmit}
                    />
                    </div>
                </CactusModal>

                <ModalDeleteConfirm 
                    show={deletingScheduleForm}
                    title={t('Delete_schedule')}
                    onCloseFunction={setDeletingScheduleForm}
                    onCloseFunctionParameter={false}
                    entityTranslation={t('schedule')}
                    buttonLoading={isLoadingCreateSchedule}
                    onClickFunctionButton={_deleteSchedule}
                    buttonText={t('Delete')}
                />
            </div>

        </DashboardWrapper>
    );

};

export default React.memo(Schedule);
