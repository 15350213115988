import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleEvent } from '../../containers/Schedule/Schedule';
import moment from 'moment';

interface CustomEventProps {
    event: ScheduleEvent;
};

const CustomEvent: React.FC<CustomEventProps> = ({event}) => {

    const [t] = useTranslation();

    const formatDate = (date: Date | undefined) => {
        return date ? moment(date).format('DD-MM-YYYY HH:mm') : '';
    };

    const getPriorityIcon = (priority: number | string) => {
        priority = Number(priority);
        switch (priority) {
            case 1:
                return <i className="iconsminds-ribbon"></i>;
            case 2:
                return <i className="iconsminds-trophy"></i>;
            default:
                return null;
        }
    };

    return(
        <div className='event'>
            <div className='event-title'>
                {event.title}
            </div>
            <div className='mt-2 event-start-end'>
                {t('From')}: {formatDate(event.start)}
            </div>
            {moment(event.end).format("YYYY-MM-DD") != '9999-12-31' ? (
                <div className='event-start-end'>
                    {t('To')}: {formatDate(event.end)}
                </div>
            ) : null}
            <div className='mt-2'>
                {event.device_type === 'App\\Models\\ScreenGroup' ? <i className="iconsminds-monitor---laptop"></i> : <i className="iconsminds-monitor"></i>} {event.device_name}
            </div>
            <div className='mt-2 event-title'>
                {getPriorityIcon(event.priority)}
            </div>
        </div>
    ); 

};

export default React.memo(CustomEvent);

