import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Navigate, ToolbarProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils from 'react-day-picker/moment';

 
const CustomCalendarToolBar: React.FC<any> = (props:ToolbarProps) => {

    let lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";
    moment.locale(lang);

    const [t] = useTranslation();

    const [viewState, setViewState] = useState('day');

    const [PanelNavLink, setPanelNavLink] = useState<number>(0);

    const DATE_FORMAT = moment.localeData().longDateFormat('L');

    const formatDate = (date: Date) => {
        return moment(date).format(DATE_FORMAT);
    };

    const parseDate = (str: string, format: string, locale: string) => {
        const parsed = moment(str, format, locale, true);
        return parsed.isValid() ? parsed.toDate() : undefined;
    };

    const goToDayView = () => {
        props.onView('day');
        setViewState('day');
    };
    const goToWeekView = () => {
        props.onView('week');
        setViewState('week');
    };
    
    const goToSpecificDate = (newDate: Date) => {
        props.onNavigate(Navigate.DATE, newDate);
    };



    return(
        <div className="rbc-toolbar">
            <div className="rbc-btn-group">
                <DayPickerInput
                    dayPickerProps={{
                        tabIndex: 1,
                        showOutsideDays: true,
                        todayButton: t('Today'),
                        localeUtils: MomentLocaleUtils, 
                        locale: lang,
                    }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format={DATE_FORMAT}
                    value={props.date}
                    onDayChange={goToSpecificDate} 
                />
            </div>
            <div className="rbc-toolbar-label">{moment(props.date).format(DATE_FORMAT)}</div>
            <div className="rbc-btn-group">
                    <nav className="nav nav-pills nav-fill">
                        <a 
                            className={`nav-link schedule-nav text-center ${PanelNavLink === 0 ? "active" : null}`}
                            href="!#"
                            onClick={(e) => {
                                e.preventDefault();
                                setPanelNavLink(0);
                                goToWeekView();
                            }}
                        >
                            {t('Week')}
                        </a>  
                        <a 
                            className={`nav-link schedule-nav text-center ${PanelNavLink === 1 ? "active" : null}`}
                            href="!#"
                            onClick={(e) => {
                                e.preventDefault();
                                setPanelNavLink(1);
                                goToDayView();
                            }}
                        >
                            {t('Day')}
                        </a>
                    </nav>
                </div>
        </div>
    );

};

export default React.memo(CustomCalendarToolBar);
