import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
    DashboardWrapper,
    DashboardTitle,
    MediaItem,
    CactusModal,
    DetailLibrary,
    CreateLibrary,
    FilterButton,
    SearchDebounce,
    BatchAction,
} from '../../components';
import Media, { getAllMediaFilters, ifShowMediabyFilter } from "../../models/Media";
import MediaItemSkeleton from "../../components/MediaItem/MediaItemSkeleton";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../../actions";
import InfiniteScroll from 'react-infinite-scroller';
import { IconLoader } from '../../assets/icons/IconLoader';
import useBatchAction from '../../hooks/useBatchAction';

interface LibraryProps {};

const Library: React.FC<LibraryProps> = (props: LibraryProps) => {

    const [t] = useTranslation();

    const [search, setSearch] = useState<string>('');
    const [filterSelected, setFilterSelected] = useState<string>(getAllMediaFilters()[0]);

    const [creatingLibrary, setCreatingLibrary] = useState<boolean>(false);
    const [selectedMedia, setSelectedMedia] = useState<Media | null>(null);
    const [disableOnClose, setDisableOnClose] = useState<boolean>(false);
    const mediaReducer = useSelector( (store: any) => store.mediaReducer );
    const pagination = mediaReducer.data;
    const media: Media[] = pagination?.data ?? [];
    const isEmpty = media.length === 0;

    const dispatch = useDispatch();

    const batchAction = useBatchAction();

    useEffect(() => {

        _fetchLibraries(1);

        return () => {
            _setEmptyMedia();
            dispatch( actionCreator.mediaCancelRequest() );
        }

    }, []);

    useEffect(() => {
        _fetchLibraries(1);
    }, [filterSelected]);

    const _fetchLibraries = (page?: number) => {
        dispatch( actionCreator.mediaCancelRequest() );
        dispatch( actionCreator.mediaFetch(page, search, filterSelected) );
    }

    const _changeFilter = (event: any) => {
        let filter: string = event.target.name;
        setFilterSelected(filter);
        batchAction.reset();
    }

    const _renderActions = () => {

        let filterElements = getAllMediaFilters().map((filter: string, index: number) => {
            return(
                <FilterButton
                    key={index}
                    className={`btn btn-cactus btn-cactus--red ${mediaReducer.isLoading ? "btn-cactus--red--loading" : ''}`}
                    onClick={_changeFilter}
                    name={filter}
                    active={filterSelected === filter}
                >
                    {t(`filter_button_${filter}`)}
                </FilterButton>
            );
        });

        return(
            <div className="library__actions d-flex justify-content-between">
                <div>
                    {filterElements}
                </div>
            </div>
        );

    }

    const _renderBatchAction = () => {

        if(isEmpty) return null;

        const batchActionView = batchAction.isSelected() ? (
            <>

                {t("Batch Action General Delete Message").split("\n").map((line: string, key: number) => (
                    <p key={key} className="small">{line}</p>
                ))}

                <button 
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                        const modelSelected: Media[] = batchAction.getModelSelected();
                        dispatch( actionCreator.mediaDelete(modelSelected, () => {
                            batchAction.reset();
                        }));
                    }}
                >
                    {t('Delete')}
                </button>

            </>
        ) : (
            <>
                <p className="small m-0">{t('Batch Action Suggestion')}</p>
            </>
        );

        return(
            <BatchAction
                isDisabled={mediaReducer.isLoading}
                isActive={batchAction.isActive}
                onClickToggleAction={() => {
                    batchAction.reset();
                    batchAction.setIsActive(!batchAction.isActive);
                }}
                selectAll={batchAction.isSelectAll(media)}
                onClickSelectAll={(all: boolean) => {
                    if(all) batchAction.selectAll(media);
                    else batchAction.deselectAll();
                }}
            >
                {batchActionView}                
            </BatchAction>
        );

    }

    const _renderNewLibraryButton = () => {

        return(
            <button 
                className={`btn btn-success ml-auto ${mediaReducer.isLoading ? "btn-cactus--loading" : null}`}
                onClick={() => {
                    setCreatingLibrary(true);
                    batchAction.reset();
                }}
                disabled={mediaReducer.isLoading}
            >
                { t('New_library') }
            </button>
        );

    }

    const _setEmptyMedia = () => {
        dispatch( actionCreator.mediaChangeValue('data', null) );
    }

    const _renderSkeletonLoading = () => {
        let skeleton = Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="col-12 col-sm-6 col-md-4 col-xl-2 mb-4">
                    <MediaItemSkeleton />
                </div>
            );
        });

        return(
            <div className="row">
                {skeleton}
            </div>
        );
    }

    const _renderLibraries = () => {

        if(mediaReducer.isLoading) {
            return _renderSkeletonLoading();
        };

        let divClass: string = "col-12 col-sm-6 col-md-4 col-xl-2 mb-4";

        let mediaItems: JSX.Element[] = media.filter((item: Media) => {
            return ifShowMediabyFilter(item, filterSelected);
        }).map((item: Media) => {
            return(
                <div key={item.id} className={divClass}>
                    <MediaItem
                        media={item}
                        onSelect={(media: Media) => {
                            setSelectedMedia(media);
                        }}
                        visibleCheck={batchAction.isActive}
                        isChecked={batchAction.isModelSelected(item)}
                        onChecked={(media: Media) => {
                            batchAction.changeModelValue(media);
                        }}
                        enableAutoPlayOnHover
                        clipVideo
                        silent
                    />
                </div>
            );
        });

        if(mediaItems.length === 0) {
            return(
                <div className="row">
                    <div className={divClass}>
                        {t('No Result')}
                    </div>
                </div>
            )
        }

        return(
            <InfiniteScroll
                pageStart={pagination?.from}
                loadMore={() => {
                    _fetchLibraries(pagination?.current_page ? pagination?.current_page + 1 : 1);
                }}
                hasMore={pagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                <div className="row">
                    {mediaItems}
                </div>
            </InfiniteScroll>
        );

    }

    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-12 col-md-6">
                    <DashboardTitle 
                        title={t("Library")} 
                        isLoading={mediaReducer.isLoading}
                        withRefreshIcon={!mediaReducer.isLoading}
                        refreshIconCallBack={() => {
                            _fetchLibraries(1);
                            batchAction.reset();
                        }}
                    />
                    <SearchDebounce
                        placeholder={`${t('Name')}, ${t('Description')}`}
                        borderColor="transparent"
                        onChange={(text: string) => setSearch(text)}
                        debounceFinish={(text: string) => {
                            _fetchLibraries(1);
                            batchAction.reset();
                        }}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex mt-1">
                        {_renderNewLibraryButton()}
                    </div>
                </div>
            </div>
            
            
            <div className="library">
                
                <div className="mt-2">
                    {_renderActions()}
                </div>

                <div className="mt-3">
                    {_renderBatchAction()}
                </div>

                <div className="library__data mt-3">
                    
                    {_renderLibraries()}
                    
                </div>

            </div>

            {/* MODALS */}
            <CactusModal 
                show={selectedMedia != null}
                removeVerticalSpacing={true}
                onClose={() => setSelectedMedia(null)}
                width="70vw"
                disableOnClose={disableOnClose}
            >
                <DetailLibrary
                    media={selectedMedia}
                    disableOnClose={(value: boolean) => {
                        setDisableOnClose(value);
                    }}
                />
            </CactusModal>

            <CactusModal 
                show={creatingLibrary}
                title={t('New_library')}
                onClose={() => {
                    setCreatingLibrary(false);
                }}
                width="70vw"
                disableOnClose={disableOnClose}
            >
                <CreateLibrary
                    disableOnClose={(value: boolean) => {
                        setDisableOnClose(value);
                    }}
                    handleNewMedia={(media: Media) => {
                        dispatch( actionCreator.mediaCreate(media) );
                    }}
                />
            </CactusModal>
            {/* END MODALS */}

        </DashboardWrapper>
    );

};

export default React.memo(Library);
