import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreator from "../../actions";

import PlaylistModel from '../../models/Playlist';

import { 
    CactusLabelInput,
    CactusModal,
    DashboardTitle, 
    DashboardWrapper, 
    PlaylistItem ,
    SearchDebounce,
} from '../../components';
import { useForm } from 'react-hook-form';
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import PlaylistService from '../../services/use_case/playlist/PlaylistService';
import InfiniteScroll from 'react-infinite-scroller';
import { IconLoader } from '../../assets/icons/IconLoader';
import PlaylistItemSkeleton from '../../components/PlaylistItem/PlaylistItemSkeleton';

interface PlaylistProps {};

export interface PlaylistInputs {
    name: string;
}

const Playlist: React.FC<PlaylistProps> = (props: PlaylistProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [search, setSearch] = useState<string>('');
    const [creatingPlaylist, setcreatingPlaylist] = useState(false)
    const [isLoadingCreatePlaylist, setisLoadingCreatePlaylist] = useState(false)

    const playlistReducer = useSelector( (store: any) => store.playlistReducer)

    const isLoading = playlistReducer?.isLoading
    const pagination = playlistReducer?.data
    const data = pagination?.data

    useEffect(() => {
        
        _fetchPlaylist(1);

        return () => {
            _setEmptyPlaylist();
            dispatch( actionCreator.playlistCancelRequest() );
        }

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _setEmptyPlaylist = () => {
        dispatch( actionCreator.playlistChangeValue('data', null) );
    }

    const _fetchPlaylist = (page?: number) => {
        dispatch( actionCreator.playlistCancelRequest() );
        dispatch( actionCreator.playlistFetch(page, search) );
    }

    const _handleCreatePlaylist = () => {
        setcreatingPlaylist(true)
    }

    const { handleSubmit, register, errors } = useForm<PlaylistInputs>();
    const textValidator = register(useFormValidatorText());

    const _handleOnSubmit = async(formData: FormData) => {
        
        setisLoadingCreatePlaylist(true);
        try {
            /*let storePlaylistResponse:AxiosResponse<ApiResponse<PlaylistModel>> =*/ await new PlaylistService().store(formData);
            
            dispatch( actionCreator.playlistFetch());
            setcreatingPlaylist(false);
            setisLoadingCreatePlaylist(false);
        } catch (err){
            setcreatingPlaylist(false);
            setisLoadingCreatePlaylist(false);
        }
    }

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className={`btn btn-cactus mt-2 ml-auto ${isLoadingCreatePlaylist && 'btn-cactus--loading'}`}
                disabled={isLoadingCreatePlaylist ?? false}
            >
                {t('Create_playlist')}
            </button>
        );
    }

    const _renderCreatePlaylistForm = () => {
        return(
            <div className="col-12">
                <form onSubmit={handleSubmit(_handleOnSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <CactusLabelInput
                                labelTitle={t('Playlist_name')}
                                inputAttributes={{
                                    id: "name",
                                    name: "name",
                                    type:  "name",
                                    placeholder: t('Name'),
                                    autoComplete: "name",
                                    ref: textValidator,
                                }}
                                errorMessage={errors.name ? errors.name.message : null}
                            />
                        </div>
                        <div className="col-12 d-flex">
                            { _renderBtnSubmit() }
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const _renderSkeletonLoading = () => {
        return Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mb-3">
                    <PlaylistItemSkeleton />
                </div>
            );
        });
    }

    const _renderPlaylist = () => {

        if(isLoading) {
            return _renderSkeletonLoading();
        }

        let playlists = data?.map( (playlist:PlaylistModel, index:number) => {
                return(
                    <PlaylistItem
                        key={index}
                        playlist={playlist}
                    />
                )
            } 
        ) ?? [];

        if(playlists.length === 0) {
            return(
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                        {t('No Result')}
                    </div>
                </div>
            )
        }

        return(
            <InfiniteScroll
                pageStart={1}
                loadMore={() => {
                    _fetchPlaylist(pagination?.current_page ? pagination?.current_page + 1 : 1)
                }}
                hasMore={pagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                {playlists}
            </InfiniteScroll>
        );

    }

    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-12 col-md-6">
                    <DashboardTitle 
                        title={t('Playlist')}
                        isLoading={isLoading}
                        withRefreshIcon={!isLoading}
                        refreshIconCallBack={() => {
                            _fetchPlaylist(1);
                        }}
                    />
                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => setSearch(text)}
                        debounceFinish={(text: string) => _fetchPlaylist(1)}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex mt-1">
                        <button 
                            className="btn btn-success ml-auto"
                            onClick={ () => _handleCreatePlaylist() }
                            disabled={isLoading}
                        >
                            { t('New_playlist') }
                        </button>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                {_renderPlaylist()}
            </div>

            <CactusModal
                show={creatingPlaylist}
                title={ t('New_playlist') }
                onClose={ () =>  setcreatingPlaylist(false) }
                minWidth="300px"
            >
                <div className="row">
                    
                    {
                        _renderCreatePlaylistForm()
                    }
                    
                </div>
            </CactusModal>
            
        </DashboardWrapper>
    );

};

export default React.memo(Playlist);
