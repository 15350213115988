import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { CactusLabelInput,
    CactusModal,
    DashboardTitle,
    DashboardWrapper,
    ScreenItem,
    ScreenPairCode,
    SearchDebounce,
} from '../../components';
import * as actionCreator from "../../actions";
import ScreenModel from '../../models/Screen';
import Playlist, { getOption } from '../../models/Playlist';
import Option from '../../models/Option';
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import ScreenService from '../../services/use_case/screen/ScreenService';
import ApiResponse from '../../services/ApiResponse';
import { AxiosResponse } from 'axios';
import PlaylistService from '../../services/use_case/playlist/PlaylistService';
import ScreenItemSkeleton from '../../components/ScreenItem/ScreenItemSkeleton';
import InfiniteScroll from 'react-infinite-scroller';
import { IconLoader } from '../../assets/icons/IconLoader';

interface ScreenProps {};

export interface ScreenInputs {
    name: string;
}

const Screen: React.FC<ScreenProps> = (props: ScreenProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [search, setSearch] = useState<string>('');
    const [defaultOption, setDefaultOption] = useState<Option[]>([]);
    const [creatingScreen, setcreatingScreen] = useState(false);
    const [isLoadingCreateScreen, setisLoadingCreateScreen] = useState(false);

    const screenResponse = useSelector( (store: any) => store.screenReducer );
    
    const isLoading = screenResponse?.isLoading
    const pagination: any = screenResponse?.data;
    const data: any = pagination?.data ?? [];

    let playlistService: PlaylistService;

    useEffect(() => {

        _fetchScreens(1);
        _fetchDefaultOptions();
        
        return () => {
            _setEmptyScreen();
            dispatch( actionCreator.screenCancelRequest() );
        }

    }, []);

    const _fetchScreens = (page?: number) => {
        dispatch( actionCreator.screenCancelRequest() );
        dispatch( actionCreator.screenFetch(page, search) );
    }

    const _setEmptyScreen = () => {
        dispatch( actionCreator.screenChangeValue('data', null) );
        setDefaultOption([]);
        playlistService?.cancelRequest();
    }
    
    const _fetchDefaultOptions = async () => {
        try {
            playlistService = new PlaylistService();
            let response: any = await playlistService.fetch();
            let playlists: Playlist[] = response.data?.data?.data ?? [];
    
            let options = playlists.map((playlist: Playlist) => getOption(playlist) );
    
            setDefaultOption(options);
        }
        catch(error) {}
    }

    const _handleOnChange = (screen: ScreenModel, option: Option) => {
        let update: ScreenModel = {
            ...screen,
            linked_id: null,
            linked_type: null,
        }

        if(option.value && option.type) {
            update = {
                ...update,
                linked_id: option.value,
                linked_type: option.type,
            }
        }
        
        dispatch( actionCreator.screenUpdate(screen.id, update) );
    };
    // --------------

    const _handleCreateScreen = () => {
        setcreatingScreen(true)
    }

    const [storedScreen, setstoredScreen] = useState<ScreenModel | null>(null)

    const { handleSubmit, register, errors } = useForm<ScreenInputs>();
    const textValidator = register(useFormValidatorText());
    
    const _handleOnSubmit = async(formData: FormData) => {

        setisLoadingCreateScreen(true);
        try {
            let storeScreenResponse:AxiosResponse<ApiResponse<ScreenModel>> = await new ScreenService().store(formData);
            setstoredScreen(storeScreenResponse.data.data)

            dispatch( actionCreator.screenFetch() );
            setisLoadingCreateScreen(false);
            
        } catch (err){
            setisLoadingCreateScreen(false);
        }
    }
    
    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className={`btn btn-cactus mt-2 ml-auto ${isLoadingCreateScreen && 'btn-cactus--loading'}`}
                disabled={isLoadingCreateScreen ?? false}
            >
                {t('Create_screen')}
            </button>
        );
    }
    
    const _renderCreateScreenForm = () => {
        return (
            <div className="col-12">
                <form onSubmit={handleSubmit(_handleOnSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <CactusLabelInput
                                labelTitle={t('Screen_name')}
                                inputAttributes={{
                                    id: "name",
                                    name: "name",
                                    type:  "name",
                                    placeholder: t('Name'),
                                    autoComplete: "name",
                                    ref: textValidator,
                                }}
                                errorMessage={errors.name ? errors.name.message : null}
                            />
                        </div>
                        <div className="col-12 d-flex">
                            { _renderBtnSubmit() }
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const _renderPairCode = () => {
        return <ScreenPairCode paircode={storedScreen && storedScreen.pair_code} />
    }

    const _renderSkeletonLoading = () => {
        return Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mb-3">
                    <ScreenItemSkeleton />
                </div>
            );
        });
    }

    const _renderScreens = () => {

        if(isLoading) {
            return _renderSkeletonLoading();
        }

        let screens: ScreenModel[] = data?.map((screen: ScreenModel, index: number) => {
            return(
                <ScreenItem
                    key={index}
                    screen={screen}
                    defaultOption={defaultOption}
                    onChange={_handleOnChange}
                />
            )
        }) ?? [];

        if(screens.length === 0) {
            return(
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                        {t('No Result')}
                    </div>
                </div>
            )
        }

        return(
            <InfiniteScroll
                pageStart={pagination?.from}
                loadMore={() => {
                    _fetchScreens(pagination?.current_page ? pagination?.current_page + 1 : 1)
                }}
                hasMore={pagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                {screens}
            </InfiniteScroll>
        );

    }
    
    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-12 col-md-6">
                    <DashboardTitle 
                        title={t('Screens')}
                        isLoading={isLoading}
                        withRefreshIcon={!isLoading}
                        refreshIconCallBack={() => {
                            _fetchScreens(1);
                        }}
                    />

                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => setSearch(text)}
                        debounceFinish={(text: string) => _fetchScreens(1)}
                    />

                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex mt-1">
                        <button
                            className="btn btn-success ml-auto"
                            disabled={isLoading}
                            onClick={ () => _handleCreateScreen() }
                        >
                            { t('New_screen') }
                        </button>
                    </div>
                </div>
            </div>

            <div className="mt-3">
                {_renderScreens()}
            </div>

            <CactusModal 
                show={creatingScreen}
                title={ t('New_screen') }
                onClose={() => {
                    setcreatingScreen(false)
                    setstoredScreen(null)
                }}
                minWidth="300px"
            >
                <div className="row">
                    
                    {
                        storedScreen? _renderPairCode() : _renderCreateScreenForm()
                    }
                    
                </div>
            </CactusModal>
            
        </DashboardWrapper>
    );

};

export default React.memo(Screen);
